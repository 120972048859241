import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { adminNavRoutes, navRoutes } from '../AuthenticatedRoute/navRoutes'
import { permissionsControl } from 'src/utils/permissionsControl'
import { decodeUserInfo } from 'src/utils/auth'

const standardPermissions = () => {
    return {
        canViewProductLibrary:
            permissionsControl(['can_view_package_list']) ||
            permissionsControl(['can_view_component_list']),
        canViewPaymentPlansList: permissionsControl([
            'can_view_estimation_list',
        ]),
        canViewCustomersList: permissionsControl(['can_view_consumer_list']),
        canViewUsersList: permissionsControl(['can_view_user_list']),
        canViewCreditVariableList: permissionsControl([
            'can_view_credit_variable_list',
        ]),
        canViewProviderList: permissionsControl(['can_view_provider_list']),
        canViewEstimationList: permissionsControl([
            'can_view_estimation_payment_plan_list',
        ]),
        canViewAlternativeRecommendation: permissionsControl([
            'can_view_consumer_alternative_list',
        ]),
        canViewConsumerProfileList: permissionsControl([
            'can_list_energy_profile',
        ]),
    }
}

const adminPermissions = () => {
    return {
        canViewCreditVariableList: permissionsControl([
            'can_view_credit_variable_list',
        ]),
        canViewProviderList: permissionsControl(['can_view_provider_list']),
        canViewEstimationList: permissionsControl([
            'can_view_estimation_payment_plan_list',
        ]),
        canViewAlternativeRecommendation: permissionsControl([
            'can_view_consumer_alternative_list',
        ]),
        canViewConsumerProfileList: permissionsControl([
            'can_list_energy_profile',
        ]),
    }
}

export const useCheckSelection = ({ isOnboardingActive }) => {
    const history = useHistory()
    const userInfo = decodeUserInfo()
    const currentUrl = history?.location?.pathname
    const isDeveloperAdmin = userInfo?.isDeveloperUser === 'true'

    const checkSelection = () => {
        const routes = isDeveloperAdmin ? adminNavRoutes : navRoutes
        const permissions = isDeveloperAdmin
            ? adminPermissions()
            : standardPermissions()

        if (isOnboardingActive) {
            return ''
        } else {
            const activeRoute = routes(...Object.values(permissions)).filter(
                route =>
                    route.routeList.some(pathPattern =>
                        new RegExp(
                            '^' + pathPattern.replace(/:\w+/g, '\\w+') + '$',
                        ).test(currentUrl),
                    ),
            )
            return activeRoute?.[0]?.key?.toString()
        }
    }
    return checkSelection
}

export const usePermittedRoutes = extraDependency => {
    const [permittedRoutes, setPermittedRoutes] = useState([])
    const userInfo = decodeUserInfo()
    const isAdmin = userInfo?.isAdminWorkspaceUser === 'true'
    const isAdminDeveloper = userInfo?.isDeveloperUser === 'true'

    const { routeType, permissions } = useMemo(() => {
        return {
            routeType: isAdminDeveloper ? adminNavRoutes : navRoutes,
            permissions: isAdminDeveloper
                ? adminPermissions()
                : standardPermissions(),
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdminDeveloper, extraDependency])

    useEffect(() => {
        const accessibleRoutes = routeType(
            ...Object.values(permissions),
        ).filter(route =>
            route.permissionRole.includes(
                isAdmin ? 'admin_workspace' : 'provider_workspace',
            ),
        )

        setPermittedRoutes(accessibleRoutes)
    }, [routeType, permissions, isAdmin, extraDependency])

    return permittedRoutes
}

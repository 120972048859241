import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { LeftOutlined } from '@ant-design/icons'
import ActionModal from '../ActionModal'
import AddComponents from './AddComponents'
import styles from './PackageName/packagename.module.scss'
import Toast from '../Toast'
import {
    addPackageApi,
    feedbackOnPackageCreationApi,
} from '../../api/products/library'
import { getProviderSubscriptionModelEnabled } from '../../api/estimations/estimations-step-one'
import useErrorHandler from '../../utils/Hooks/useErrorHandler'
import AddCapexReplacement from './AddCapexReplacement'
import { eventTrackers } from '../../utils/eventTrackers.js'
import { appTracking } from '../../utils/appTracker.js'
import { errorHandler } from '../../utils/errorHandler'
import { denyPermission } from '../../utils/permissionFramework'
import { decodeUserInfo } from 'src/utils/auth'
import GlobalPackage from './GlobalPackage'
import {
    hasShownNPSModalInLast30Days,
    useUpdateNPSValues,
} from 'src/utils/handleNPSValues'
import NPSRatingModal from '../NPSRatingModal'
import { v4 as uuidv4 } from 'uuid'
import AddProtectiveDevice from './AddProtectiveDevice'
import useMargin from './hooks/useMargin'

const ProductPackage = () => {
    const { MobileBackLink } = styles
    const userInfo = decodeUserInfo()
    const isGlobalContributor = userInfo.globalContributor === 'true'
    const [stage, setStage] = useState('name')
    const [packageName, setPackageName] = useState('')
    const [packageDescription, setPackageDescription] = useState('')
    const [isGlobal, setIsGlobal] = useState(null)
    const [usePowerCurveReco, setUserPowerCurveReco] = useState(null)
    const [packageWarranty, setPackageWarranty] = useState('')
    const [packagePaymentPlanTypes, setPackagePaymentPlanTypes] = useState([])
    const [selected, setSelected] = useState([])
    const [count, setCount] = useState([])
    const [margin, setMargin] = useState({
        action: '',
        amount: '',
        percentage: '',
        isExisting: false,
    })
    const [marginUnit, setMarginUnit] = useState(0)
    const [components, setComponents] = useState([])
    const [open, setOpen] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])
    const [isSubscription, setIsSubscription] = useState(false)
    const [createSuccessful, setCreateSuccessful] = useState(false)
    const [selectedProtectiveDevicesId, setSelectedProtectiveDevicesId] =
        useState([])
    const [remoteMonitoringCapabilities, setRemoteMonitoringCapabilities] =
        useState('')
    const [packageId, setPackageId] = useState('')
    const [showNPSRatingModal, setShowNPSRatingModal] = useState(false)
    const [additionalPayload, setAdditionalPayload] = useState({})
    const [createProtectiveDevice, setCreateProtectiveDevice] = useState([
        {
            id: uuidv4(),
            name: '',
            category: '',
            rating: '',
            quantity: '',
            unit_of_measurement: 'Amps (A)',
        },
    ])

    const history = useHistory()
    const {
        margin: outrightSaleMargin,
        setMargin: setOutrightSaleMargin,
        total: totalForOutrightSale,
        subtotal: subtotalForOutrightSale,
        handleRemoveMargin: handleRemoveMarginForOutrightSale,
        getMargin: getMarginForOutrightSale,
        marginUnit: marginUnitForOutrightSale,
    } = useMargin(count)

    const { npsState, updateNPSAndResetValues } = useUpdateNPSValues(
        'packageCreationNPSDisplayed',
    )
    const canShowNpsModal = !hasShownNPSModalInLast30Days(
        npsState?.packageCreationNPSDisplayed,
    )
    const sunfiId = history?.location?.state?.sunfiId ?? ''
    const isAdminWorkspace = history?.location?.state?.isAdminWorkspace ?? false
    const providerName = history?.location?.state?.name ?? ''

    const pageTitle = 'SunFi - Add A Package | Create Package'
    const pageUrlName = window.location.pathname
    const pageTracker = 'ADD_PACKAGE_TRACKER'
    const [errors] = useState({})
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'

    const handleStage = value => {
        setStage(value)
    }

    const handlePackageName = value => {
        setPackageName(value)
    }

    const handlePackageDescription = value => {
        setPackageDescription(value)
    }

    const handlePackageIsGlobal = value => {
        setIsGlobal(value)
    }

    const handleUsePowerCurveReco = value => {
        setUserPowerCurveReco(value)
    }

    const handlePackageWarranty = value => {
        setPackageWarranty(value)
    }

    const handlePackagePaymentPlanTypes = values => {
        setPackagePaymentPlanTypes(values)
    }

    const handleProtectiveDevice = values => {
        setSelectedProtectiveDevicesId(values)
    }
    useEffect(() => {
        if (denyPermission('admin', 'product_library', 'package:can_create')) {
            history.push('/app/403-page')
        }
    }, [history])

    const handleClose = () => {
        setOpen(false)
        redirectTo('/app/products')
    }

    const handleOpen = () => {
        setOpen(!open)
    }

    const handleNewPackage = () => {
        setOpen(false)
        setStage('name')
        setPackageName('')
        setPackageDescription('')
        setPackageWarranty('')
        setPackagePaymentPlanTypes([])
        setIsGlobal(null)
        setUserPowerCurveReco(null)
        setComponents([])
        setSelected([])
        setMargin({
            action: '',
            amount: '',
            percentage: '',
            isExisting: false,
        })
        setOutrightSaleMargin({
            action: '',
            amount: '',
            percentage: '',
            isExisting: false,
        })
        setSelectedProtectiveDevicesId([])
    }

    const handleMargin = (amount, percent) => {
        setMarginUnit(percent)
    }

    const handleComponents = list => {
        let formattedList = []
        list.forEach(value => {
            formattedList.push({
                id: value?.id,
                quantity: value?.count,
                capex_replacement_schedule: value?.capex_replacement_schedule
                    ?.filter(
                        item => item.cost.length > 0 && item.month.length > 1,
                    )
                    .map(item => {
                        return {
                            cost: item.cost.replaceAll(',', ''),
                            month: Number(item.month.replace(/\D/g, '')),
                        }
                    }),
            })
        })
        setComponents(formattedList)
    }
    const { isFetching: addPackageFetching, refetch: addPackageRefetch } =
        useQuery(
            ['addPackage'],
            () =>
                addPackageApi(
                    {
                        name: packageName,
                        ...(marginUnit !== '' && { margin: marginUnit }),
                        ...(isAdminUser &&
                            marginUnitForOutrightSale !== '' && {
                                outright_sale_margin: parseFloat(
                                    marginUnitForOutrightSale,
                                ),
                            }),
                        description: packageDescription,
                        components,
                        ...(isGlobalContributor && {
                            is_global: isGlobal,
                        }),
                        ...(isAdminUser &&
                            process.env
                                .REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE ===
                                'true' &&
                            usePowerCurveReco !== null &&
                            usePowerCurveReco !== 'none' && {
                                is_powercurve_supported: usePowerCurveReco,
                            }),
                        warranty: packageWarranty,
                        subscription_enabled: packagePaymentPlanTypes.find(
                            record => record?.value === 'Subscription',
                        )
                            ? true
                            : false,
                        l2O_enabled: packagePaymentPlanTypes.find(
                            record => record?.value === 'Lease to Own',
                        )
                            ? true
                            : false,
                        ...(isAdminUser && {
                            outright_sale_supported:
                                packagePaymentPlanTypes.find(
                                    record => record?.value === 'Outright sale',
                                )
                                    ? true
                                    : false,
                        }),
                        ...(sunfiId !== '' ? { provider_id: sunfiId } : {}),
                        ...(process.env
                            .REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE ===
                            'true' && {
                            create_protective_devices:
                                createProtectiveDevice.map(device => {
                                    return ['Overall Protection'].includes(
                                        device.category,
                                    )
                                        ? {
                                              name: device.name,
                                              category: device.category,
                                              quantity: device.quantity,
                                          }
                                        : {
                                              name: device.name,
                                              category: device.category,
                                              unit_of_measurement:
                                                  device.unit_of_measurement ||
                                                  'Amps (A)',
                                              rating: device.rating,
                                              quantity: device.quantity,
                                          }
                                }),
                        }),
                        remote_monitoring_capability:
                            remoteMonitoringCapabilities,
                    },
                    isAdminWorkspace,
                ),
            {
                enabled: false,
                retry: false,
                onSuccess: res => {
                    const data = res?.data?.data
                    setPackageId(data?.id)
                    if (canShowNpsModal && !isAdminWorkspace) {
                        setShowNPSRatingModal(true)
                        updateNPSAndResetValues()
                    } else {
                        setOpen(true)
                    }
                    setCreateSuccessful(true)
                    isGlobal === true
                        ? appTracking(
                              pageUrlName,
                              pageTracker,
                              pageTitle,
                              eventTrackers['addGlobalPackage'].action,
                              eventTrackers['addGlobalPackage'].label,
                              eventTrackers['addGlobalPackage'].category,
                          )
                        : appTracking(
                              pageUrlName,
                              pageTracker,
                              pageTitle,
                              eventTrackers['addPackage'].action,
                              eventTrackers['addPackage'].label,
                              eventTrackers['addPackage'].category,
                          )
                },
                onError: data => {
                    if (data?.response?.data?.errors) {
                        setErrorMessage(errorHandler(data?.response?.data))
                    } else {
                        setErrorMessage([
                            'Failed to create package. Refresh/Try Again',
                        ])
                    }
                    setToastError(true)
                },
            },
        )

    // check if subscription model is enabled

    const { refetch: getProviderSubscriptionModelRefetch } = useQuery(
        'getProviderSubscriptionModel',
        () => getProviderSubscriptionModelEnabled(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setIsSubscription(data?.data?.data)
            },
        },
    )

    const feedbackOnPackageCreationMutation = useMutation({
        mutationFn: () =>
            feedbackOnPackageCreationApi(packageId, additionalPayload),
        retry: false,
        onSuccess: () => {
            proceedToSuccessMsg()
        },
        onError: () => {
            proceedToSuccessMsg()
        },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => getProviderSubscriptionModelRefetch(), [])

    const handleSavePackage = () => {
        setToastError(false)
        addPackageRefetch()
    }

    const errorValue = useErrorHandler(errors)
    const prevLocation =
        history?.location?.state?.prev === 'all products library'

    const redirectTo = pageUrl => {
        history.push(
            (prevLocation && !createSuccessful) || sunfiId.length < 0
                ? pageUrl
                : {
                      pathname: pageUrl,
                      state: {
                          sunfiId: sunfiId,
                          isAdminWorkspace: isAdminWorkspace,
                          name: providerName,
                      },
                  },
        )
    }

    const proceedToSuccessMsg = () => {
        setShowNPSRatingModal(false)
        setOpen(true)
    }
    return (
        <>
            <ActionModal
                actionModalOpen={open}
                headerText="Package Created Successfully "
                subTitle="Packages help you move faster. You’re on your way to reaching more energy consumers."
                closeModal={handleClose}
                actionHandler={handleNewPackage}
                actionType="success"
                actionText="Add New Package"
                cancelText="Back to Products"
            />
            <NPSRatingModal
                showModal={showNPSRatingModal}
                closeModal={() => proceedToSuccessMsg()}
                feature={'created-package'}
                setAdditionalPayload={setAdditionalPayload}
                handleClick={() => feedbackOnPackageCreationMutation.mutate()}
                btnLoading={feedbackOnPackageCreationMutation.isLoading}
            />
            <div
                style={{ marginLeft: '100px' }}
                className={MobileBackLink}
                onClick={
                    prevLocation
                        ? () => redirectTo('/admin/products')
                        : () => redirectTo('/app/products')
                }
            >
                <LeftOutlined
                    style={{ color: 'var(--blue)', marginRight: 10 }}
                />

                <a style={{ color: 'var(--blue)' }}>Back to products</a>
            </div>
            {toastError && (
                <Toast
                    messageType="error"
                    message={errorMessage[0] || errorValue['msg']}
                />
            )}
            {stage === 'name' ? (
                <GlobalPackage
                    handleStage={handleStage}
                    packageName={packageName}
                    packageDescription={packageDescription}
                    isGlobal={isGlobal}
                    usePowerCurveReco={usePowerCurveReco}
                    handlePackageName={handlePackageName}
                    handlePackageDescription={handlePackageDescription}
                    handlePackageIsGlobal={handlePackageIsGlobal}
                    handleUsePowerCurveReco={handleUsePowerCurveReco}
                    handlePackageWarranty={handlePackageWarranty}
                    packageWarranty={packageWarranty}
                    handlePackagePaymentPlanTypes={
                        handlePackagePaymentPlanTypes
                    }
                    packagePaymentPlanTypes={packagePaymentPlanTypes}
                    handleProtectiveDevice={handleProtectiveDevice}
                    selectedProtectiveDevices={selectedProtectiveDevicesId}
                    setSelectedProtectiveDevices={
                        setSelectedProtectiveDevicesId
                    }
                    remoteMonitoringCapabilities={remoteMonitoringCapabilities}
                    setRemoteMonitoringCapabilities={
                        setRemoteMonitoringCapabilities
                    }
                />
            ) : stage === 'components' ? (
                <AddComponents
                    handleStage={handleStage}
                    name={packageName}
                    handleMargin={(amount, percent) =>
                        handleMargin(amount, percent)
                    }
                    selected={selected}
                    margin={margin}
                    setMargin={setMargin}
                    outrightSaleMargin={outrightSaleMargin}
                    setSelected={setSelected}
                    count={count}
                    setCount={setCount}
                    handleSavePackage={handleSavePackage}
                    isSubscription={isSubscription}
                    handleComponents={handleComponents}
                    saveLoading={addPackageFetching}
                    packagePaymentPlanTypes={packagePaymentPlanTypes}
                    totalForOutrightSale={totalForOutrightSale}
                    subtotalForOutrightSale={subtotalForOutrightSale}
                    handleRemoveMarginForOutrightSale={
                        handleRemoveMarginForOutrightSale
                    }
                    getMarginForOutrightSale={getMarginForOutrightSale}
                    packageWarranty={packageWarranty}
                    handlePackageWarranty={handlePackageWarranty}
                    stage={stage}
                />
            ) : stage === 'capex' ? (
                <AddCapexReplacement
                    handleStage={handleStage}
                    handleOpen={handleOpen}
                    name={packageName}
                    displayedComponents={selected}
                    setDisplayedComponents={setSelected}
                    handleSavePackage={handleSavePackage}
                    saveLoading={addPackageFetching}
                    handleComponents={handleComponents}
                />
            ) : stage === 'protective_device' ? (
                <AddProtectiveDevice
                    handleStage={handleStage}
                    handleSavePackage={handleSavePackage}
                    saveLoading={addPackageFetching}
                    createProtectiveDevice={createProtectiveDevice}
                    setCreateProtectiveDevice={setCreateProtectiveDevice}
                />
            ) : null}
        </>
    )
}
export default ProductPackage

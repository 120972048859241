/* eslint-disable no-undef */
import * as React from 'react'

import { Switch, Route, useLocation } from 'react-router-dom'

// SunFi App Routes
import AppContainerRoute from '../components/AppContainerRoute'
import AuthenticatedRoute from '../components/AuthenticatedRoute'
import Signup from '../pages/Signup'
import CompleteSignup from '../pages/CompleteSignup'
import Login from '../pages/Login'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'
import Onboarding from '../pages/Onboarding'
import VerifyEmail from '../pages/VerifyEmail'
import Verify from '../pages/ResetPassword/Verify'
import AppNotFound from '../pages/AppNotFound'
import UnAuthorizedPage from '../pages/UnAuthorizedPage'
import NotFound from '../pages/NotFound'
import ProductLibrary from '../pages/Products/Library'
import Estimations from '../pages/Estimations'
import CreateEstimations from '../pages/CreateEstimations'
import EstimationsStepOne from '../pages/EstimationsStepOne'
import AddComponent from '../pages/Products/AddComponent'
import EditComponent from '../pages/Products/EditComponent'
import ViewPackage from '../pages/Products/ViewPackage'
import ViewComponent from '../pages/Products/ViewComponent'
import Overview from '../pages/Overview'
import DEPStaffSignUp from '../pages/DEPStaffSignUp/stepOne'
import StepTwoDEPStaffSignUp from '../pages/DEPStaffSignUp/stepTwo'
import Profile from '../pages/Profile'
import EstimationsStepTwo from '../pages/EstimationsStepTwo'
import EstimationsStepThree from '../pages/EstimationStepThree'
import ProductPackage from '../components/ProductPackage'
import EditPackage from '../components/ProductPackage/EditPackage'
import WorkspaceStatus from '../pages/WorkspaceStatus'
import RepaymentSchedule from '../pages/Consumer/RepaymentSchedule'
import LoginStatus from '../pages/Consumer/LoginStatus'
import SimulationStepOne from '../pages/SimulationStepOne'
import SimulationStepTwo from '../pages/SimulationStepTwo'
import AdminOverview from '../pages/Admin/Overview'
import AdminCreditListings from '../pages/Admin/Credit/Listings'
import AdminUsersListing from '../pages/Admin/Users/Listing'
import ViewCreditVariable from '../pages/Admin/Credit/ViewVariable'
import AdminGroupsAndPermissions from '../pages/Admin/Users/AddGroupAndPermissions'
import AdminProvidersListings from '../pages/Admin/Providers/Listings'
import AdminApprovedProviders from '../pages/Admin/Providers/Active'
import AdminDeactivatedProvider from '../pages/Admin/Providers/Deactivated'
import AdminSettings from '../pages/Admin/Settings/Listings'
import AdminProducts from '../pages/Admin/Products/Listings'
import ProviderCustomerListings from '../pages/Provider/Customers/Listings'
import AdminPendingProviders from '../pages/Admin/Providers/Pending'
import ActiveCustomers from '../pages/Provider/Customers/Active'
import ProviderUser from 'src/pages/Admin/Providers/Active/providerUser'
import AdminProviderGroups from 'src/pages/Admin/Settings/ProviderGroups/AdminProviderGroups'
import AdminComponentTypes from 'src/pages/Admin/Settings/ComponentTypes'
import AdminProviderResources from '../pages/Admin/Settings/ProviderResources'
import AdminUserDetails from 'src/pages/Admin/Users/UserDetails'
import AdminGroups from 'src/pages/Admin/Users/AdminGroups'
import UpdateProviderGroupPermissions from 'src/pages/Admin/Settings/ProviderGroups/UpdateProviderGroupPermissions'
import UpdateAdminGroupPermissions from '../pages/Admin/Users/AdminGroups/UpdateAdminGroupPermissions'
import ProviderGroups from 'src/pages/Admin/Settings/ProviderGroups/ProviderGroups'
import UpdateProvidersGroupPermissions from 'src/pages/Admin/Settings/ProviderGroups/UpdateProvidersGroupPermissions'
import Welcome from 'src/pages/Welcome'
import SSELogin from 'src/pages/Consumer/SmartShoppingExperience/SSELogin'
import ProviderClusterLibrary from 'src/pages/EstimationsStepOne/ProviderClusterLibrary'
import ConsumerWorkspace from 'src/pages/Consumer/ConsumerWorkspace'
import PaymentPlansListings from 'src/pages/Admin/PaymentPlans/Listings'
import PaymentPlanDetails from 'src/pages/Admin/PaymentPlans/Details'
import AlternateRecommendations from 'src/pages/Admin/AlternateRecommendation/Listings'
import AlternativeRecommendationDetails from 'src/pages/Admin/AlternateRecommendation/Details'
import SelectedAlternativeRecommendations from 'src/pages/Admin/AlternateRecommendation/SelectedAlternativeRecommendations'
import { permissionsControl } from 'src/utils/permissionsControl'
import NewProfile from 'src/pages/Admin/Profiles/NewProfile'
import AdminProfilesDetails from 'src/pages/Admin/Profiles/Details'
import AdminProfiles from 'src/pages/Admin/Profiles/Listings'
import AddPackage from 'src/pages/Admin/Profiles/AddPackage'
import AdminProfileReview from 'src/pages/Admin/Profiles/Review'
import OutrightSalesListings from 'src/pages/Admin/OutrightSale/Listings'
import OutrightSalesDetails from 'src/pages/Admin/OutrightSale/Details'
import AdminProfilesGroups from 'src/pages/Admin/Profiles/Groups/Listings'
import ProfileKYCInformation from 'src/pages/Admin/Profiles/NewProfile/KycInfo'
import ProfileClusterInformation from 'src/pages/Admin/Profiles/NewProfile/ClusterInfo'
import REPLogin from 'src/pages/Representative/Login/REPLogin'
import RepresentativeWorkspace from 'src/pages/Representative/Workspace'
import ProfileClusterBanner from 'src/pages/Admin/Profiles/NewProfile/ClusterBannner'
import ErrorNotice from 'src/components/ErrorNotice'

const AppRoutes = () => {
    const location = useLocation()

    const [showEstimationTwo, setShowEstimationTwo] = React.useState()
    const [showEstimationThree, setShowEstimationThree] = React.useState()

    const isDepStaffVerified = localStorage.getItem('sunfiToken')
    const isEstimationTwoStore = localStorage.getItem('isEstimationTwo')
    const isPaymentPlanStepTwo =
        JSON.parse(isEstimationTwoStore) || location.state?.isEstimationTwo
    const isEstimationThreeStore = localStorage.getItem('isEstimationThree')
    const isPaymentPlanStepThree =
        JSON.parse(isEstimationThreeStore) || location.state?.isEstimationThree
    const canViewPaymentPlansList = permissionsControl([
        'can_view_estimation_list',
    ])
    const canViewEstimationList = permissionsControl([
        'can_view_estimation_payment_plan_list',
    ])
    const canViewEstimationDetails = permissionsControl([
        'an_view_estimation_payment_plan_detail',
    ])
    const canViewProductLibrary =
        permissionsControl(['can_view_package_list']) ||
        permissionsControl(['can_view_component_list'])

    const canViewCustomersList = permissionsControl(['can_view_consumer_list'])
    const canViewUsersList = permissionsControl(['can_view_user_list'])
    const canViewUsersDetails = permissionsControl(['can_view_user_details'])
    const canViewCreditVariableList = permissionsControl([
        'can_view_credit_variable_list',
    ])
    const canViewCreditVariableDetails = permissionsControl([
        'can_view_credit_variable_details',
    ])
    const canViewProviderList = permissionsControl(['can_view_provider_list'])
    const canViewProviderUserDetails = permissionsControl([
        'can_view_provider_user_detail',
    ])

    const showNewErrorNotice =
        process.env.REACT_APP_FLAG_SHOW_NEW_ERROR_AND_BROKEN_LINK_PAGE ===
        'true'

    const canViewConsumerProfileList =
        canViewProductLibrary && permissionsControl(['can_list_energy_profile'])

    const canViewEnergyProfileDetails = permissionsControl([
        'can_retrieve_energy_profile',
    ])

    const canCreateEnergyProfile = permissionsControl([
        'can_create_energy_profile',
    ])

    const canUpdateEnergyProfile = permissionsControl([
        'can_update_energy_profile',
    ])

    const canViewProfileGroups = permissionsControl([
        'can_list_energy_profile_group',
    ])

    React.useEffect(() => {
        let estimationTwoUrls =
            location.pathname === '/app/payment-plans/edit/two' ||
            location.pathname === '/app/payment-plans/create/two' ||
            location.pathname === '/app/payment-plans/edit/three' ||
            location.pathname === '/app/payment-plans/create/three' ||
            location.pathname === '/app/payment-plans/cluster-package'
                ? true
                : false
        setShowEstimationTwo(estimationTwoUrls)
        showEstimationTwo === false &&
            localStorage.removeItem('isEstimationTwo')
    }, [location.pathname, showEstimationTwo])

    React.useEffect(() => {
        let estimationThreeUrls =
            location.pathname === '/app/payment-plans/edit/three' ||
            location.pathname === '/app/payment-plans/create/three'
                ? true
                : false
        setShowEstimationThree(estimationThreeUrls)
        showEstimationThree === false &&
            localStorage.removeItem('isEstimationThree')
    }, [location.pathname, showEstimationThree])
    return (
        <Switch>
            <AppContainerRoute
                path="/signup"
                component={Signup}
                noText={true}
            />
            <AppContainerRoute
                path="/create-account"
                component={CompleteSignup}
            />
            <AppContainerRoute path="/login" component={Login} />
            <AppContainerRoute
                path="/consumer/welcome"
                component={Welcome}
                type="sign-in"
            />
            <AppContainerRoute
                path="/consumer/login"
                component={SSELogin}
                type="sign-in"
            />
            {process.env.REACT_APP_FLAG_SHOW_REPRESENTATIVE_WORKSPACE ===
                'true' && (
                <AppContainerRoute
                    path="/rep/login"
                    component={REPLogin}
                    type="sign-in"
                />
            )}
            <AppContainerRoute
                path="/forgot-password"
                component={ForgotPassword}
                noText={true}
            />
            <AppContainerRoute
                path="/reset-password"
                component={ResetPassword}
                noText={true}
            />
            <AuthenticatedRoute
                exact
                path="/onboarding"
                component={Onboarding}
            />
            {canViewProductLibrary && (
                <AuthenticatedRoute
                    exact
                    path="/app/products"
                    component={ProductLibrary}
                />
            )}
            <AuthenticatedRoute
                exact
                path="/admin/overview"
                component={AdminOverview}
            />

            {canViewEstimationList && (
                <AuthenticatedRoute
                    exact
                    path="/admin/plans"
                    component={PaymentPlansListings}
                    bgColor={'#FDFEFF'}
                />
            )}

            {canViewEstimationDetails && (
                <AuthenticatedRoute
                    exact
                    path="/admin/plans/:id"
                    component={PaymentPlanDetails}
                    bgColor={'#FDFEFF'}
                />
            )}

            <AuthenticatedRoute
                exact
                path="/admin/outright-sale"
                component={OutrightSalesListings}
                bgColor={'#FDFEFF'}
            />

            <AuthenticatedRoute
                exact
                path="/admin/outright-sale/:id"
                component={OutrightSalesDetails}
                bgColor={'#FDFEFF'}
            />

            <AuthenticatedRoute
                exact
                path="/admin/alternative-recommendations"
                component={AlternateRecommendations}
                bgColor={'#FDFEFF'}
            />

            <AuthenticatedRoute
                exact
                path="/admin/alternative-recommendations/:id"
                component={AlternativeRecommendationDetails}
                bgColor={'#FDFEFF'}
            />
            <AuthenticatedRoute
                exact
                path="/admin/alternative-recommendations/selected/:id"
                component={SelectedAlternativeRecommendations}
                bgColor={'#FDFEFF'}
            />
            {/* Admin Users page routes */}
            {process.env.REACT_APP_FLAG_SHOW_ADMIN_USERS === 'true' && (
                <AuthenticatedRoute
                    exact
                    path="/admin/users/listing"
                    component={AdminUsersListing}
                />
            )}
            {process.env.REACT_APP_FLAG_SHOW_ADMIN_USERS === 'true' && (
                <AuthenticatedRoute
                    exact
                    path="/admin/users/add-group"
                    component={AdminGroupsAndPermissions}
                />
            )}
            {process.env.REACT_APP_FLAG_SHOW_ADMIN_SETTINGS === 'true' && (
                <AuthenticatedRoute
                    exact
                    path="/admin/settings"
                    component={AdminSettings}
                />
            )}
            {process.env.REACT_APP_FLAG_SHOW_ADMIN_SETTINGS === 'true' && (
                <AuthenticatedRoute
                    exact
                    path="/admin/settings/provider-groups/:id"
                    component={AdminProviderGroups}
                />
            )}
            {process.env.REACT_APP_FLAG_SHOW_ADMIN_SETTINGS === 'true' && (
                <AuthenticatedRoute
                    exact
                    path="/admin/settings/component-types/:id"
                    component={AdminComponentTypes}
                />
            )}
            {process.env.REACT_APP_FLAG_SHOW_ADMIN_SETTINGS === 'true' && (
                <AuthenticatedRoute
                    exact
                    path="/admin/settings/provider-resources/:id"
                    component={AdminProviderResources}
                />
            )}
            {process.env.REACT_APP_FLAG_SHOW_ADMIN_SETTINGS === 'true' && (
                <AuthenticatedRoute
                    exact
                    path="/admin/settings/update-group-permissions/:id"
                    component={UpdateProviderGroupPermissions}
                />
            )}
            {process.env.REACT_APP_FLAG_SHOW_ADMIN_USERS === 'true' && (
                <AuthenticatedRoute
                    exact
                    path="/admin/user/:id"
                    component={AdminUserDetails}
                />
            )}
            {process.env.REACT_APP_FLAG_SHOW_ADMIN_USERS === 'true' && (
                <AuthenticatedRoute
                    exact
                    path="/admin/group/:id"
                    component={AdminGroups}
                />
            )}
            {process.env.REACT_APP_FLAG_SHOW_ADMIN_USERS === 'true' && (
                <AuthenticatedRoute
                    exact
                    path="/admin/group/update-group-permissions/:id"
                    component={UpdateAdminGroupPermissions}
                />
            )}
            {canViewCreditVariableList && (
                <AuthenticatedRoute
                    exact
                    path="/admin/credit"
                    component={AdminCreditListings}
                />
            )}
            {canViewCreditVariableDetails && (
                <AuthenticatedRoute
                    exact
                    path="/admin/credit/view/:id"
                    component={ViewCreditVariable}
                />
            )}
            {canViewProviderList && (
                <AuthenticatedRoute
                    exact
                    path="/admin/providers"
                    component={AdminProvidersListings}
                />
            )}
            <AuthenticatedRoute
                exact
                path="/admin/providers/active/:id"
                component={AdminApprovedProviders}
            />
            <AuthenticatedRoute
                exact
                path="/admin/providers/pending/:id"
                component={AdminPendingProviders}
            />
            <AuthenticatedRoute
                exact
                path="/admin/providers/pending/:id"
                component={AdminPendingProviders}
            />
            <AuthenticatedRoute
                exact
                path="/admin/providers/deactivated/:id"
                component={AdminDeactivatedProvider}
            />
            {canViewProviderUserDetails && (
                <AuthenticatedRoute
                    exact
                    path="/admin/providers/user/:id"
                    component={ProviderUser}
                />
            )}
            <AuthenticatedRoute
                exact
                path="/admin/products"
                component={AdminProducts}
            />
            {canCreateEnergyProfile && (
                <AuthenticatedRoute
                    exact
                    path="/admin/consumer-profiles/add-consumer-profile"
                    component={NewProfile}
                />
            )}
            {canUpdateEnergyProfile && (
                <AuthenticatedRoute
                    exact
                    path="/admin/consumer-profiles/edit-consumer-profile/:id"
                    component={NewProfile}
                />
            )}
            {canCreateEnergyProfile && (
                <AuthenticatedRoute
                    exact
                    path="/admin/consumer-profiles/add-kyc-information"
                    component={ProfileKYCInformation}
                />
            )}
            {canUpdateEnergyProfile && (
                <AuthenticatedRoute
                    exact
                    path="/admin/consumer-profiles/edit-kyc-information"
                    component={ProfileKYCInformation}
                />
            )}
            {canCreateEnergyProfile && (
                <AuthenticatedRoute
                    exact
                    path="/admin/consumer-profiles/add-cluster-information"
                    component={ProfileClusterInformation}
                />
            )}
            {canCreateEnergyProfile && (
                <AuthenticatedRoute
                    exact
                    path="/admin/consumer-profiles/add-cluster-banner"
                    component={ProfileClusterBanner}
                />
            )}
            {canUpdateEnergyProfile && (
                <AuthenticatedRoute
                    exact
                    path="/admin/consumer-profiles/edit-cluster-information/:profile_id"
                    component={ProfileClusterInformation}
                />
            )}
            {canUpdateEnergyProfile && (
                <AuthenticatedRoute
                    exact
                    path="/admin/consumer-profiles/edit-cluster-banner/:profile_id"
                    component={ProfileClusterBanner}
                />
            )}
            {canCreateEnergyProfile && (
                <AuthenticatedRoute
                    exact
                    path="/admin/consumer-profiles/add-package"
                    component={AddPackage}
                />
            )}
            {canViewConsumerProfileList && (
                <AuthenticatedRoute
                    exact
                    path="/admin/consumer-profiles"
                    component={AdminProfiles}
                />
            )}
            {canViewEnergyProfileDetails && (
                <AuthenticatedRoute
                    exact
                    path={'/admin/consumer-profiles/:id'}
                    component={AdminProfilesDetails}
                />
            )}
            {canViewProfileGroups && (
                <AuthenticatedRoute
                    exact
                    path="/admin/consumer-profiles-groups"
                    component={AdminProfilesGroups}
                />
            )}
            {canCreateEnergyProfile && (
                <AuthenticatedRoute
                    exact
                    path={'/admin/consumer-profiles/review/:id'}
                    component={AdminProfileReview}
                    hideNavOnMobile
                />
            )}

            <AuthenticatedRoute
                exact
                path="/app/products/add-package"
                component={ProductPackage}
            />
            <AuthenticatedRoute
                exact
                path="/app/products/edit-package/:id"
                component={EditPackage}
            />
            <AuthenticatedRoute
                exact
                path="/app/overview"
                component={Overview}
            />
            {canViewPaymentPlansList && (
                <AuthenticatedRoute
                    exact
                    path="/app/payment-plans"
                    component={Estimations}
                />
            )}
            <AuthenticatedRoute
                exact
                path="/app/payment-plans/create"
                component={CreateEstimations}
            />
            <AuthenticatedRoute
                exact
                path="/app/payment-plans/create/one"
                component={EstimationsStepOne}
            />
            <AuthenticatedRoute
                exact
                path="/app/payment-plans/cluster-package"
                component={ProviderClusterLibrary}
            />
            {canViewCustomersList && (
                <AuthenticatedRoute
                    exact
                    path="/app/customers"
                    component={ProviderCustomerListings}
                />
            )}
            <AuthenticatedRoute
                exact
                path="/app/customers/active/:id"
                component={ActiveCustomers}
            />
            <AuthenticatedRoute
                exact
                path="/app/customers/awaiting-approval/:id"
                component={ActiveCustomers}
            />
            <AuthenticatedRoute
                exact
                path="/app/customers/approved/:id"
                component={ActiveCustomers}
            />
            <AuthenticatedRoute
                exact
                path="/app/customers/awaiting-update/:id"
                component={ActiveCustomers}
            />
            <AuthenticatedRoute
                exact
                path="/app/customers/re-estimate/:id"
                component={ActiveCustomers}
            />
            <AuthenticatedRoute
                exact
                path="/app/customers/declined/:id"
                component={ActiveCustomers}
            />
            <AuthenticatedRoute
                exact
                path="/app/customers/closed/:id"
                component={ActiveCustomers}
            />

            {process.env.REACT_APP_FLAG_SHOW_PROVIDER_USERS === 'true' &&
                canViewUsersList && (
                    <AuthenticatedRoute
                        exact
                        path="/app/users/listing"
                        component={AdminUsersListing}
                    />
                )}
            {process.env.REACT_APP_FLAG_SHOW_PROVIDER_USERS === 'true' &&
                canViewUsersDetails && (
                    <AuthenticatedRoute
                        exact
                        path="/app/user/:id"
                        component={ProviderUser}
                    />
                )}
            {process.env.REACT_APP_FLAG_SHOW_PROVIDER_USERS === 'true' && (
                <AuthenticatedRoute
                    exact
                    path="/app/users/add-group"
                    component={AdminGroupsAndPermissions}
                />
            )}
            {process.env.REACT_APP_FLAG_SHOW_PROVIDER_USERS === 'true' && (
                <AuthenticatedRoute
                    exact
                    path="/app/group/:id"
                    component={ProviderGroups}
                />
            )}
            {process.env.REACT_APP_FLAG_SHOW_PROVIDER_USERS === 'true' && (
                <AuthenticatedRoute
                    exact
                    path="/app/group/update-group-permissions/:id"
                    component={UpdateProvidersGroupPermissions}
                />
            )}
            {isPaymentPlanStepTwo && (
                <AuthenticatedRoute
                    exact
                    path="/app/payment-plans/create/two"
                    component={EstimationsStepTwo}
                />
            )}
            {isPaymentPlanStepThree && (
                <AuthenticatedRoute
                    exact
                    path="/app/payment-plans/create/three"
                    component={EstimationsStepThree}
                />
            )}
            <AuthenticatedRoute
                exact
                path="/app/payment-plans/edit/one/:id"
                component={EstimationsStepOne}
            />
            {isPaymentPlanStepTwo && (
                <AuthenticatedRoute
                    exact
                    path="/app/payment-plans/edit/two"
                    component={EstimationsStepTwo}
                />
            )}
            {isPaymentPlanStepThree && (
                <AuthenticatedRoute
                    exact
                    path="/app/payment-plans/edit/three"
                    component={EstimationsStepThree}
                />
            )}
            <AuthenticatedRoute
                exact
                path="/app/payment-plans/create/two"
                component={EstimationsStepTwo}
            />
            {isPaymentPlanStepThree && (
                <AuthenticatedRoute
                    exact
                    path="/app/payment-plans/create/three"
                    component={EstimationsStepThree}
                />
            )}
            <AuthenticatedRoute
                exact
                path="/app/payment-plans/edit/one/:id"
                component={EstimationsStepOne}
            />
            {isPaymentPlanStepTwo && (
                <AuthenticatedRoute
                    exact
                    path="/app/payment-plans/edit/two"
                    component={EstimationsStepTwo}
                />
            )}
            {isPaymentPlanStepThree && (
                <AuthenticatedRoute
                    exact
                    path="/app/payment-plans/edit/three"
                    component={EstimationsStepThree}
                />
            )}
            <AuthenticatedRoute
                exact
                path="/app/payment-plans/simulate/one"
                component={SimulationStepOne}
            />
            <AuthenticatedRoute
                exact
                path="/app/payment-plans/simulate/two"
                component={SimulationStepTwo}
            />
            <AuthenticatedRoute
                exact
                path="/app/products/add-component"
                component={AddComponent}
            />
            <AuthenticatedRoute
                exact
                path="/app/products/edit-component/:id"
                component={EditComponent}
            />
            <AuthenticatedRoute
                exact
                path="/app/products/view-package/:id"
                component={ViewPackage}
            />
            <AuthenticatedRoute
                exact
                path="/app/products/view-component/:id"
                component={ViewComponent}
            />
            <AuthenticatedRoute exact path="/app/profile" component={Profile} />
            <AppContainerRoute
                path="/verify-email-confirmation-link"
                component={VerifyEmail}
                noText={true}
            />
            <AppContainerRoute
                path="/consumer/verify-email-confirmation-link"
                component={VerifyEmail}
                noText={true}
            />
            <AppContainerRoute
                exact
                path="/verify-reset-password-link"
                component={Verify}
            />
            <AppContainerRoute
                exact
                path="/workspace-status"
                component={WorkspaceStatus}
            />
            <AppContainerRoute
                exact
                path="/verify-user-email-confirmation-link"
                component={DEPStaffSignUp}
            />
            {isDepStaffVerified && (
                <AppContainerRoute
                    exact
                    path="/workspace/member-signup"
                    noText={true}
                    component={StepTwoDEPStaffSignUp}
                />
            )}

            <AppContainerRoute
                path="/consumer/login-status"
                component={LoginStatus}
                noText={true}
            />
            <AuthenticatedRoute
                path="/consumer/repayment-schedule"
                component={RepaymentSchedule}
            />
            <AuthenticatedRoute
                exact
                path="/consumer/workspace"
                component={ConsumerWorkspace}
            />
            {process.env.REACT_APP_FLAG_SHOW_REPRESENTATIVE_WORKSPACE ===
                'true' && (
                <AuthenticatedRoute
                    path="/rep/workspace"
                    component={RepresentativeWorkspace}
                />
            )}

            <AuthenticatedRoute
                path="/app/not-found"
                component={AppNotFound}
                additionalContentBoxClassName={
                    showNewErrorNotice ? 'ContentBox_NotFoundWrapper' : null
                }
            />
            <AuthenticatedRoute
                path="/app/403-page"
                component={UnAuthorizedPage}
            />
            <Route
                path="*"
                component={showNewErrorNotice ? ErrorNotice : NotFound}
            />
        </Switch>
    )
}

export default AppRoutes

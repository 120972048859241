import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import '../accountsetupoverview.scss'
import '../../ViewSystem/viewsystem.scss'
import NairaSymbol from 'src/components/NairaSymbol'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import fallbackImage from 'src/assets/images/fallback.svg'
import { promoBanners } from '../../Home/PromoCarousel/data'
import { decrypt } from 'src/utils/cryptography'
import { ReactComponent as MoneyIcon } from 'src/assets/images/moneys.svg'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import ActionModal from 'src/components/ActionModalV2'
import { useGetMinMaxUpfront, useUpdateUpfrontDeposit } from '../api'
import { IconInputField } from 'src/components/InputFields/CostField'
import { useCustomFormik } from 'src/utils/Hooks/useCustomFormik'
import NairaIcon from 'src/assets/images/naira-sign.svg'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import * as Yup from 'yup'
import { Skeleton } from 'antd'
import { useQueryClient } from 'react-query'
import ConsumerLoader from 'src/components/ConsumerLoader'
import { floatWithCommas } from 'src/utils/formatting'
import { decodeUserInfo } from 'src/utils/auth'
import { useAppContext } from 'src/appContext/AppContext'
import CustomToolTip from 'src/components/CustomToolTip'
import { ReactComponent as HelpIcon } from 'src/assets/images/help-circle.svg'

const Loader = () => {
    return (
        <>
            {new Array(4).fill(0).map((_, idx) => (
                <div className="RepaymentPlansLoaderContainer" key={idx}>
                    <Skeleton active />
                </div>
            ))}
        </>
    )
}
const SelectedSolutionCard = ({
    estimation,
    goBackToSystem,
    systemAccepted,
    hidePackageInfoBtn = false,
    planType,
    isPromo,
    upfrontDepositPaid,
    hasPaidOutrightly,
}) => {
    const { isCustomerApproved } = useAppContext()
    const promoDetails =
        localStorage.getItem('promoDetails') &&
        JSON.parse(decrypt(localStorage.getItem('promoDetails')))
    const promoType = promoDetails?.promoType
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'

    const queryClient = useQueryClient()
    const [isApplyingNewUpfrontAmount, setIsApplyingNewUpfrontPayment] =
        useState(false)
    const [showUpfrontPaymentFailure, setShowUpfrontPaymentFailure] =
        useState(false)
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [showConfirmPayment, setShowConfirmPayment] = useState(false)
    const [showUpfrontPaymentSuccess, setShowUpfrontPaymentSuccess] =
        useState(false)

    const { data: minMaxUpfrontDeposit } = useGetMinMaxUpfront(
        estimation?.id,
        {
            enabled:
                !isCustomerApproved && !!estimation?.id && !hasPaidOutrightly,
        },
        isAdminUser,
    )
    const minimumUpfrontDeposit =
        minMaxUpfrontDeposit?.data?.minimum_upfront_deposit
    const maximumUpfrontDeposit =
        minMaxUpfrontDeposit?.data?.maximum_upfront_deposit
    const currentUpfrontDeposit =
        minMaxUpfrontDeposit?.data?.current_upfront_deposit
    const numberRegex = /^[0-9.,]*$/
    const [upfrontDeposit, setUpfrontDeposit] = useState('')
    const {
        setFieldValue: setNewUpfrontDeposit,
        handleBlur,
        values,
        errors,
        touched,
        resetForm,
        setFieldTouched,
    } = useCustomFormik({
        initialValues: {
            newUpfrontDeposit: '',
        },
        validateOnBlur: true,
        validationSchema: Yup.object().shape({
            newUpfrontDeposit: Yup.number()
                .min(
                    minimumUpfrontDeposit,
                    `The minimum upfront deposit is ${currencyFormatter(
                        minimumUpfrontDeposit,
                    )}`,
                )
                .max(
                    maximumUpfrontDeposit,
                    `The maximum upfront deposit is ${currencyFormatter(
                        maximumUpfrontDeposit,
                    )}`,
                )
                .required('Please provide upfront deposit'),
        }),
    })
    const { isMobile } = useMediaQueries()
    const {
        data: updatedPaymentPlans,
        mutate: mutateUpfrontDeposit,
        isLoading: isIncreaseUpfrontDepositLoading,
        error,
    } = useUpdateUpfrontDeposit(
        {
            onSettled: () => {
                setIsApplyingNewUpfrontPayment(false)
            },
        },
        isAdminUser,
    )

    const handleNewUpfrontDepositChange = e => {
        setFieldTouched('newUpfrontDeposit', true)
        let val = e.target.value.replaceAll(/[^0-9]/g, '')
        if (val.length > 1 && val.startsWith('0')) {
            val = parseInt(val)
        }
        setNewUpfrontDeposit('newUpfrontDeposit', val)
    }
    const handleRefetchRepaymentPlans = () => {
        if (touched.newUpfrontDeposit && !errors.newUpfrontDeposit) {
            mutateUpfrontDeposit({
                estimationId: estimation?.id,
                payload: {
                    amount: values.newUpfrontDeposit,
                    persist_change: false,
                },
            })
        }
    }
    const repaymentPlans = updatedPaymentPlans?.data
    useEffect(() => {
        if (currentUpfrontDeposit && estimation?.id) {
            mutateUpfrontDeposit({
                estimationId: estimation?.id,
                payload: {
                    amount: currentUpfrontDeposit,
                    persist_change: false,
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUpfrontDeposit])
    return (
        <>
            {isApplyingNewUpfrontAmount && (
                <ConsumerLoader
                    position="fixed"
                    overlayWidth="100vw"
                    overlayHeight="100vh"
                    text="Applying"
                />
            )}

            <ActionModal
                handlePrimaryBtnClick={() => {
                    mutateUpfrontDeposit(
                        {
                            estimationId: estimation?.id,
                            payload: {
                                amount: values.newUpfrontDeposit,
                                persist_change: true,
                            },
                        },
                        {
                            onSuccess: () => {
                                setShowUpfrontPaymentSuccess(true)
                            },
                            onError: () => {
                                setShowUpfrontPaymentFailure(true)
                            },
                        },
                    )
                    setShowConfirmPayment(false)
                    setShowPaymentModal(false)
                    setIsApplyingNewUpfrontPayment(true)
                }}
                onCancel={() => setShowConfirmPayment(false)}
                actionModalOpen={showConfirmPayment}
                headerText="Increase your upfront deposit"
                actionType="warning"
                title="You’re about to increase your upfront deposit"
                subTitle={
                    <p>
                        Kindly confirm you want to increase{' '}
                        {isAdminUser ? 'the consumer ' : 'your'} upfront deposit
                        to <NairaSymbol />{' '}
                        {currencyFormatter(values.newUpfrontDeposit)}. This
                        means this is the first payment{' '}
                        {isAdminUser ? 'the consumer will' : 'you’ll'} be making
                        for this plan
                    </p>
                }
                primaryBtnText="Confirm"
            />
            <ActionModal
                handlePrimaryBtnClick={() => {
                    setShowUpfrontPaymentSuccess(false)
                    window.location.reload()
                }}
                onCancel={() => {
                    setShowUpfrontPaymentSuccess(false)
                    window.location.reload()
                }}
                actionModalOpen={showUpfrontPaymentSuccess}
                headerText="Upfront deposit increased"
                actionType="success"
                title="Your upfront deposit has been increased"
                subTitle={
                    <p>
                        You have successfully increased how much you want{' '}
                        {isAdminUser ? 'the consumer ' : ' '}to pay for this
                        package to <NairaSymbol />{' '}
                        {currencyFormatter(values.newUpfrontDeposit)}
                    </p>
                }
                primaryBtnText="Done"
            />
            <ActionModal
                handlePrimaryBtnClick={() => {
                    setShowUpfrontPaymentFailure(false)
                }}
                onCancel={() => setShowUpfrontPaymentFailure(false)}
                actionModalOpen={showUpfrontPaymentFailure}
                headerText="Error"
                actionType="delete"
                title="Increasing upfront deposit failed"
                subTitle={error?.response?.data?.message}
                primaryBtnText="Done"
            />

            <SecondaryModal
                showModal={showPaymentModal}
                onCancel={() => {
                    setShowPaymentModal(false)
                    resetForm()
                    queryClient.setQueryData('increase-upfront-deposit', null)
                }}
                modalWidth={'649px'}
                v2
                title={'All available payment plans for this package'}
                showFooter
                content={
                    <>
                        <div
                            className={
                                'ViewSystemL20PlanCards ASOUpdateUpfrontDeposit'
                            }
                        >
                            <h6>
                                Increase the upfront deposit you’ll like
                                {isAdminUser ? ' the consumer ' : ' '}to pay for
                                this package
                            </h6>
                            <p>
                                Enter an amount that is not less than{' '}
                                <NairaSymbol />{' '}
                                {currencyFormatter(minimumUpfrontDeposit)}
                            </p>
                            <IconInputField
                                icon={NairaIcon}
                                name="newUpfrontDeposit"
                                value={upfrontDeposit}
                                handleChange={e => {
                                    const value = e.target.value
                                    handleNewUpfrontDepositChange(e)
                                    setUpfrontDeposit(
                                        numberRegex.test(value)
                                            ? floatWithCommas(value)
                                            : '',
                                    )
                                }}
                                title={`Enter new upfront deposit`}
                                onBlur={e => {
                                    handleBlur(e)
                                    handleRefetchRepaymentPlans()
                                }}
                                width="100%"
                                errorMessage={
                                    touched.newUpfrontDeposit &&
                                    errors.newUpfrontDeposit
                                }
                            />
                            <h6>
                                All payment plans available for this package
                            </h6>
                            {isIncreaseUpfrontDepositLoading ? (
                                <Loader />
                            ) : (
                                repaymentPlans?.map((item, i) => (
                                    <div
                                        key={i}
                                        className={
                                            'ViewSystemL20PlanCard ModalVersion'
                                        }
                                    >
                                        <div
                                            className={
                                                'ViewSystemL20PlanCardRowOne'
                                            }
                                        >
                                            <h4>
                                                <span>
                                                    {item?.tenure} monthly
                                                </span>
                                                {isMobile ? <br /> : ' '}
                                                payments of
                                            </h4>
                                            <h5>
                                                <span>
                                                    <NairaSymbol />{' '}
                                                    {item?.monthly_payment?.toLocaleString(
                                                        'en-US',
                                                    )}{' '}
                                                </span>
                                                / Month
                                            </h5>
                                        </div>
                                        <div
                                            className={
                                                'ViewSystemL20PlanCardRowTwo'
                                            }
                                        >
                                            <span>Initial deposit of</span>
                                            <p>
                                                <NairaSymbol />{' '}
                                                {item?.initial_deposit?.toLocaleString(
                                                    'en-US',
                                                )}
                                            </p>
                                        </div>
                                        {process.env
                                            .REACT_APP_INSURANCE_INCLUSIVE_PAYMENT_PLANS_ENABLED ===
                                            'true' &&
                                            item?.investment_meta_data
                                                ?.monthly_insurance_amount && (
                                                <div
                                                    className={
                                                        'ViewSystemL20PlanCard_InsuranceRow AlignCenter'
                                                    }
                                                >
                                                    <span>
                                                        Insurance amount of
                                                    </span>
                                                    <p>
                                                        <NairaSymbol />{' '}
                                                        {item?.investment_meta_data?.monthly_insurance_amount?.toLocaleString(
                                                            'en-US',
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        <div
                                            className={
                                                'ViewSystemL20PlanCard_TotalRow'
                                            }
                                        >
                                            <span>Total payment of</span>
                                            <p>
                                                <NairaSymbol />
                                                {item?.investment_meta_data?.total_paid?.toLocaleString(
                                                    'en-US',
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </>
                }
                primaryBtnText={'Apply'}
                primaryBtnDisabled={
                    isIncreaseUpfrontDepositLoading ||
                    errors.newUpfrontDeposit ||
                    !values.newUpfrontDeposit
                }
                primaryBtnWidth={isMobile ? '105px' : '150px'}
                primaryBtnHeight={isMobile ? '45px' : '53px'}
                handlePrimaryBtnClick={() => {
                    setShowPaymentModal(false)
                    setShowConfirmPayment(true)
                }}
            />
            <div className={'ASOSelectedSolutionContent'}>
                <LazyLoadImage
                    src={
                        isPromo && promoType !== undefined
                            ? promoBanners[promoType]
                            : estimation?.has_solar_panel
                            ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/With+panels+thumbnail.png'
                            : 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Without+panels+thumbnail.png'
                    }
                    placeholderSrc={fallbackImage}
                    alt="image of the system"
                />

                <div className={'ASOSelectedSolutionContent_Heading'}>
                    <div className={'ASOSelectedSolutionContentText'}>
                        <p>{estimation?.system_name}</p>
                        <h4>{estimation?.capacity}</h4>
                    </div>
                    {!hidePackageInfoBtn && (
                        <span
                            className={'ASOSeePackageBtn'}
                            role={'button'}
                            onClick={() => goBackToSystem()}
                        >
                            See package info
                        </span>
                    )}
                </div>
                <div>
                    <div className={'ASOSelectedSolutionContentText'}>
                        <span>Total price</span>
                        <h6>
                            <NairaSymbol />
                            {estimation?.total_price}
                        </h6>
                    </div>
                    <div className={'ASOSelectedSolutionContentText Right'}>
                        {estimation?.isOutrightSaleCustomer ? (
                            <>
                                <span>Back-up hours</span>
                                <h5>{estimation?.backupHours + ' hours'}</h5>
                            </>
                        ) : (
                            <>
                                <span>Est. price per month</span>
                                <h5>
                                    <NairaSymbol />
                                    {estimation?.price_per_month}
                                </h5>
                            </>
                        )}
                    </div>
                </div>
                {planType !== 'Subscription' && !hasPaidOutrightly && (
                    <div className="ASOPaymentInfo">
                        <div>
                            <div className={'ASOSelectedSolutionContentText'}>
                                <span>Est. months of repayment </span>
                                <h5>
                                    {estimation?.repayment_months_count} months
                                </h5>
                            </div>
                            <div
                                hidden={upfrontDepositPaid}
                                className={'ASOSelectedSolutionContentText'}
                            >
                                <span>Upfront Deposit </span>
                                <h5>
                                    <NairaSymbol />
                                    {estimation?.upfront_deposit_amount}
                                </h5>
                            </div>
                        </div>
                        <div>
                            <button
                                hidden={upfrontDepositPaid}
                                onClick={() => setShowPaymentModal(true)}
                                className="primary-button small-btn"
                            >
                                <MoneyIcon />
                                Increase upfront deposit
                            </button>
                        </div>
                    </div>
                )}
                {systemAccepted && (
                    <div className={'ASOSelectedSolution_Flag Approved'}>
                        <span>PACKAGE APPROVED</span>
                    </div>
                )}
                {estimation?.consumer_status === 'DECLINED_AND_RE_ESTIMATE' && (
                    <div className={'ASOSelectedSolution_Flag Disputed'}>
                        <span>PACKAGE DISPUTED</span>

                        <CustomToolTip
                            text={
                                'You expressed dissatisfaction with the package your provider selected for you.'
                            }
                            arrowPositionCenter
                            toolTipPopupWidth="200px"
                            toolTipMarginLeft={-155}
                        >
                            <HelpIcon stroke="white" />
                        </CustomToolTip>
                    </div>
                )}
            </div>
        </>
    )
}

SelectedSolutionCard.propTypes = {
    estimation: PropTypes.object,
    goBackToSystem: PropTypes.func,
    systemAccepted: PropTypes.bool,
    hidePackageInfoBtn: PropTypes.bool,
    planType: PropTypes.string,
    isPromo: PropTypes.string,
    upfrontDepositPaid: PropTypes.bool,
    hasPaidOutrightly: PropTypes.bool,
}

export default SelectedSolutionCard

import * as yup from 'yup'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { replaceHtmlTags } from './formatting'

export const phoneNumberRegex = /^\d{10,11}$/
// const phoneNumberRegexWithNigeriaCode = /^(\+234|0)\d{10}$/
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
export const websiteRegex =
    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/

export const nameRegex = /^[a-zA-Z-]*$/
const componentNameRegex = /^[a-zA-Z0-9@'/"&_()-: _,\s]{2,}$/
const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]+$/
export const DriversLicenseRegex = /^[a-zA-Z0-9]{7,}$/
export const InternationalPassportRegex = /^[a-zA-Z0-9]{8,9}$/
export const VotersCardRegex = /^[a-zA-Z0-9]{19}$/
export const VirtualNINRegex = /^[a-zA-Z0-9]{16}$/
export const amountRegex = /^[0-9.,]*$/
export const RcNumberRegex = /^([A-Za-z]{2})?\d{6,8}$/
export const fullNameRegex = /^[a-zA-Z]{2,}\s[a-zA-Z]{2,}/
export const paystackSubscriptionCodeRegex =
    /^([S]{1}[U]{1}[B]{1}_)?[a-z\d]{15,15}$/
export const googleDriveLinkRegex = /^https:\/\/drive.google.com?(\/.*)?$/
export const bvnRegex = /^\d{11},*$/

export const cacNumberRegex = /^(BN|RC|LP|IT|LLP|KW)?[0-9]{5,10}$/
const validateNameRegex = /^[a-zA-Z-]+$/
export const singleNameRegex = /^[^\s]+$/

export const CompanyInfoValidationSchema = page => {
    const generalObject = {
        address: yup.string().required('Address is required'),
        city: yup.string().required('City is required'),
        state: yup.string().required('Please select a state'),
        size: yup.string().required('Please select a company size'),
        website: yup
            .string()
            .required('Website is required')
            .matches(
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/,
                'Enter valid website url',
            ),
        businessType: yup.string().required('Please select a business type'),
        ...(page === 'onboarding'
            ? {
                  businessName: yup
                      .string()
                      .required('Please enter your business rc number'),
                  rcNumber: yup
                      .string()
                      .required('Please enter your business rc number')
                      .matches(RcNumberRegex, 'Please enter a valid RC number'),
              }
            : {}),
    }

    if (page === 'onboarding') {
        return yup.object().shape(generalObject)
    } else {
        const validationObject = {
            ...generalObject,
            companyName: yup.string().required('Company name is required'),
        }
        return yup.object().shape(validationObject)
    }
}

export const PersonalInfoValidationSchema = yup.object().shape({
    address: yup.string().required('Please fill in your street address'),
    city: yup.string().required('Please select LGA'),
    state: yup.string().required('Please select your state'),
    qualification: yup
        .string()
        .required('Please select your highest qualification'),
    bio: yup
        .string()
        .matches(/^[a-zA-Z0-9_ ].{1,500}$/)
        .required('Please tell us about yourself'),
})

export const AILoginPhoneValidationSchema = yup.object().shape({
    phonenumber: yup
        .string()
        .matches(phoneNumberRegex, 'Please enter a valid phone number'),
    password: yup.string().required('Password is required'),
})

export const DISILoginEmailValidationSchema = yup.object().shape({
    email: yup
        .string()
        .required('Email is required')
        .email('Enter a valid email address'),
    password: yup.string().required('Password is required'),
})

export const CompanyContactValidationSchema = page => {
    const generalObject = {
        firstName: yup
            .string()
            .required('First name is required')
            .matches(nameRegex, 'Use only letters here'),
        lastName: yup
            .string()
            .required('Last name is required')
            .matches(nameRegex, 'Use only letters here'),
        role: yup.string().required('Please select a role'),
    }

    if (page === 'onboarding') {
        return yup.object().shape(generalObject)
    } else {
        const validationObject = {
            ...generalObject,
            phoneNumber: yup
                .string()
                .matches(phoneNumberRegex, 'Please enter a valid phone number'),
            email: yup
                .string()
                .email('Enter a valid email address')
                .required('Email is required'),
        }
        return yup.object().shape(validationObject)
    }
}

export const AIValidateSchema = type => {
    let emailOrPhone
    if (type === 'phone') {
        emailOrPhone = yup
            .string()
            .matches(phoneNumberRegex, 'Please enter a valid phone number')
            .required('Phone number is required')
    } else if (type === 'email') {
        emailOrPhone = yup
            .string()
            .email('Enter a valid email address')
            .required('Email is required')
    }
    const passwordSchema = yup
        .string()
        .matches(
            passwordRegex,
            'Password must be at least one UPPERCASE, lowercase and a number',
        )
        .min(8, 'Password is too short - should be minimum of 8 characters.')

    return yup.object().shape({
        [type === 'phone' ? 'phonenumber' : 'email']: emailOrPhone,
        password: passwordSchema,
        firstname: yup.string().matches(nameRegex, 'Use only letters here'),
        lastname: yup.string().matches(nameRegex, 'Use only letters here'),
    })
}

export const DISIValidateSchema = type => {
    let emailOrPhone

    const companyNameSchema = yup
        .string()
        .required(`Please enter your company's name`)

    if (type === 'phone') {
        emailOrPhone = yup
            .string()
            .matches(phoneNumberRegex, 'Please enter a valid phone number')
            .required('Phone number is required')
    } else if (type === 'email') {
        emailOrPhone = yup
            .string()
            .email('Enter a valid email address')
            .required('Email is required')
    }
    const passwordSchema = yup
        .string()
        .matches(
            passwordRegex,
            'Password must be at least one UPPERCASE, lowercase and a number',
        )
        .min(8, 'Password is too short - should be minimum of 8 characters.')

    return yup.object().shape({
        companyname: companyNameSchema,
        [type === 'phone' ? 'phonenumber' : 'email']: emailOrPhone,
        password: passwordSchema,
    })
}

export const WorkExperienceValidationSchema = yup.object().shape({
    companyname: yup
        .string()
        .nullable()
        .required('Company/Project name is required'),
    startDate: yup.date().nullable().required('Start date is required'),
    tech: yup.array().min(1, 'Select at least one technology'),
    description: yup.string().nullable().required('Description is required'),
    role: yup.string().nullable().required('Role is required'),
})

export const IdentityVerificationDEPOnboardingValidationSchema = yup
    .object()
    .shape({
        id_type: yup.string().required('This field is required'),
        id_image: yup
            .string()
            .required('Upload a means of identification to continue'),
        passport: yup
            .string()
            .required(
                'Upload a picture or take a selfie to move to the next stage',
            ),
    })

export const AIResetPhoneValidationSchema = yup.object().shape({
    phonenumber: yup
        .string()
        .matches(phoneNumberRegex, 'Please enter a valid phone number'),
})

export const DISIResetEmailValidationSchema = yup.object().shape({
    email: yup.string().email('Enter a valid email address'),
})

export const CreateAccountPhoneValidationSchema = yup.object().shape({
    phonenumber: yup
        .string()
        .matches(phoneNumberRegex, 'Please enter a valid phone number'),
})

export const CreateAccountEmailValidationSchema = yup.object().shape({
    email: yup.string().email('Enter a valid email address'),
})

export const CreateComponentValidationSchema = yup.object().shape({
    componentName: yup
        .string()
        .required('Component name is required')
        .matches(
            componentNameRegex,
            `Component name should be at least 2 characters and can only contain any of the following characters: /@'"&_(): _,-`,
        ),
    componentCost: yup.string().required('Cost is required'),
    componentType: yup.string().required('Select a component type'),
    ...(process.env.REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE === 'true' && {
        brandName: yup.string().required('Brand Name is required'),
    }),
    componentWarranty: yup
        .number()
        .nullable()
        .transform((value, originalValue) => {
            const trimmed = originalValue.trim()
            return trimmed === '' || isNaN(trimmed) ? null : parseFloat(trimmed)
        })
        .when('$checkWarranty', {
            is: true,
            then: yup
                .number()
                .required('Warranty is required')
                .typeError('Warranty is required'),
            otherwise: yup.number().nullable(),
        }),
})

export const editComponentValidationSchema = yup.object().shape({
    componentName: yup
        .string()
        .required('Component name is required')
        .matches(
            componentNameRegex,
            `Component name should be at least 2 characters and can only contain any of the following characters: /@'"&_(): _,-`,
        ),
    componentCost: yup.string().required('Cost is required'),
    componentWarranty: yup
        .number()
        .nullable()
        .transform((value, originalValue) => {
            const trimmed = originalValue.trim()
            return trimmed === '' || isNaN(trimmed) ? null : parseFloat(trimmed)
        })
        .when('$checkWarranty', {
            is: true,
            then: yup
                .number()
                .required('Warranty is required')
                .typeError('Warranty is required'),
            otherwise: yup.number().nullable(),
        }),
    componentDescription: yup
        .string()
        .test(
            'required-check',
            'Component description is required',
            function (value) {
                const cleanValue = replaceHtmlTags(value)
                return cleanValue.length > 2
            },
        ),
})

export const AIupdateInfoValidationSchema = yup.object().shape({
    street: yup.string().required('Address is required').nullable(),
    city: yup.string().required('City is required').nullable(),
    state: yup.string().required('Please select a state').nullable(),
    bio: yup
        .string()
        .matches(/^[a-zA-Z0-9_ ].{1,500}$/)
        .required('Maximum of 500 characters'),
    email: yup.string().required('Please enter an email adress'),
    firstName: yup.string().required('Please enter your first name'),
    lastName: yup.string().required('Please enter your last name'),
    phoneNumber: yup
        .string()
        .required('Please enter your phone number')
        .matches(phoneNumberRegex, 'Please enter a valid phone number'),
})

export const PasswordFieldsValidationSchema = yup.object().shape({
    newPassword: yup.string().required('Password is required'),
    oldPassword: yup.string().required('Password is required'),
})

export const EstimationStepOneValidationSchema = yup.object().shape({
    first_name: yup
        .string()
        .required('This field is required')
        .matches(validateNameRegex, 'Please enter a valid first name'),
    last_name: yup
        .string()
        .required('This field is required')
        .matches(validateNameRegex, 'Please enter a valid surname'),
    email: yup.lazy(value =>
        value.length == 0
            ? yup.string()
            : yup.string().email('Please enter a valid email address'),
    ),
    phone_number: yup
        .string()
        .required('This field is required')
        .test('validate_phone', 'Please enter a valid phone number', data =>
            isValidPhoneNumber(data),
        ),
    customer_acquisition: yup.string().required('Please select an option'),
    repayment_method: yup.string().required('Please select an option'),
})

export const EstimationStepTwoValidationSchema = yup.object().shape({
    financing_term: yup.string().required('Please select an option'),
    selectPackageFrom: yup.string().required('Please select an option'),
    upfront_deposit: yup.string().when('selectPackageFrom', {
        is: 'Shared-Cluster Library',
        then: yup.string(),
        otherwise: yup.string().required('This field is required'),
    }),
    system_ease_of_recovery: yup.string().required('Please select an option'),
    remote_monitoring_capabilities: yup
        .string()
        .required('Please select an option'),
    appliances: yup.string().required('Please select an appliance'),
    ...(process.env.REACT_APP_FLAG_SHOW_INSTALLATION_DETAILS === 'true' && {
        genSize: yup.string().required('This field is required'),
        installationSiteReady: yup.string().required('This field is required'),
        roofAssessment: yup.string().required('This field is required'),
        installationAwareness: yup.string().required('This field is required'),
        projectComments: yup.string().required('This field is required'),
    }),
})

export const DepStaffValidationSchema = yup.object().shape({
    firstName: yup.string().required('This field is required'),
    lastName: yup.string().required('This field is required'),
    phoneNumber: yup
        .string()
        .required('This field is required')
        .matches(phoneNumberRegex, 'Please enter a valid phone number'),
    role: yup.string().required('This field is required'),
    password: yup
        .string()
        .matches(
            passwordRegex,
            'Password must be at least one UPPERCASE, lowercase and a number',
        )
        .min(8, 'Password is too short - should be minimum of 8 characters.'),
})

export const IdentityCheck = {
    'Voters Card': {
        identityVerification: yup
            .string()
            .required('Please enter your phone number')
            .matches(
                VotersCardRegex,
                'Voter’s Card Number should be 19 characters',
            ),
    },
    'Drivers License': {
        identityVerification: yup
            .string()
            .required('Please enter your phone number')
            .matches(DriversLicenseRegex, 'Drivers License number is wrong'),
    },
}

export const RequestUpdateValidationSchema = yup.object().shape({
    title: yup.string().required('Request Title is required').nullable(),
    description: yup.string().required('Add Short Note').nullable(),
})

export const RequestUpfrontDepositValidationSchema = yup.object().shape({
    notes: yup
        .string()
        .min(1, 'Notes must be at least 1 characters.')
        .max(255, 'Notes has a maximum limit of 255 characters.')
        .required('Add Short Note')
        .nullable(),
})

export const ConsumerHelpQuestionValidationSchema = yup.object().shape({
    fullName: yup.string().required('Fullname is required'),
    message: yup
        .string()
        .matches(/^[a-zA-Z0-9_ ].{10,}$/, 'Minimum of 10 characters'),
    email: yup.string().required('Please enter an email adress'),
    fullPhoneNumber: yup
        .string()
        .required('Please enter your phone number')
        .test('validate_phone', 'Please enter a valid phone number', data =>
            isValidPhoneNumber(data),
        ),
})

export const NubanAccountDetailsValidationSchema = yup.object().shape({
    bankName: yup.string().required('Bank Name is required'),
    accountNumber: yup
        .string()
        .required('Please enter your account number')
        .matches(/^\d{10}$/, 'Please enter a valid account number'),
})

export const InviteUserValidationSchema = yup.object({
    users: yup.array().of(
        yup.object().shape({
            email: yup
                .string()
                .email('Enter a valid email address')
                .required('Please enter an email address'),
            //change back to required when select group is deployed to production
            groups: yup.array().optional('Select at least one group'),
        }),
    ),
})

export const AdminInviteUserValidationSchema = yup.object({
    users: yup.array().of(
        yup.object().shape({
            email: yup
                .string()
                .email('Enter a valid email address')
                .required('Please enter an email address'),
            groups: yup.string().required('Please select a group'),
        }),
    ),
})

export const consumerHomeValidationSchema = (
    type,
    next,
    knowsCostOfGen,
    generatorUse,
    paymentModel,
    canMountSolar,
) => {
    let emailOrPhone
    let generatorSpend
    let generatorMaintenance
    let generatorSize
    let knowsMonthlyGenCostErr
    let paymentModelErr
    let canMountSolarErr

    if (type === 'phone') {
        emailOrPhone = yup
            .string()
            .matches(phoneNumberRegex, 'Please enter a valid phone number')
            .required('Phone number is required')
    } else if (type === 'email') {
        emailOrPhone = yup
            .string()
            .email('Enter a valid email address')
            .required('Email is required')
    }

    if (generatorUse) {
        if (knowsCostOfGen !== 'invalid' && knowsCostOfGen === 'yes') {
            generatorSpend = yup
                .string()
                .typeError('Please enter a generator maintenance')
                .required('Please enter a generator maintenance')
                .min(
                    1,
                    'Please enter a positive number for generator maintenance',
                )
        } else if (knowsCostOfGen !== 'invalid' && knowsCostOfGen === 'no') {
            generatorSize = yup
                .string()
                .required('Please select a generator size')
            generatorMaintenance = yup
                .string()
                .required('Please select a generator maintenance')
        }
    }

    if (knowsCostOfGen === '') {
        knowsMonthlyGenCostErr = yup
            .string()
            .typeError('This is required')
            .required('This is required')
    }

    if (paymentModel === undefined) {
        paymentModelErr = yup
            .string()
            .typeError('This is required')
            .required('This is required')
    }

    if (canMountSolar === null) {
        canMountSolarErr = yup
            .boolean()
            .typeError('This is required')
            .required('This is required')
    }

    switch (next) {
        case false:
            return yup.object().shape({
                [type]: emailOrPhone,
                location: yup.string().required('Please select a location'),
                paymentPlan: yup
                    .string()
                    .required('Please select your payment plan duration'),
                knowsMonthlyGenCostErr,
                reasonForSolar: yup
                    .string()
                    .required(
                        'Please select the primary goal to solve with Solar',
                    ),
            })
        case true:
            return yup.object().shape({
                monthlyPayment: yup
                    .string()
                    .typeError('Please enter a monthly payment')
                    .required('Please enter a monthly payment')
                    .min(
                        1,
                        'Please enter a positive number for monthly payment',
                    ),
                generatorSpend,
                generatorMaintenance,
                generatorSize,
                paymentModelErr,
                canMountSolarErr,
                electricityPerDay: yup
                    .string()
                    .required('Please enter your daily electricity usage'),
            })
    }
}

export const consumerHomeValidationV2Schema = (
    contactMethod,
    useType,
    useTypeRequired,
    urgency,
    referralSource,
    phoneErrorFromApi,
    emailErrorFromApi,
) => {
    let emailOrPhone
    let useTypeErr
    let urgencyErr
    let referralSourceErr
    let phoneErrCheck
    let emailErrCheck

    if (contactMethod === 'phone') {
        emailOrPhone = yup
            .string()
            .matches(phoneNumberRegex, 'Please enter a valid phone number')
            .required('Please enter your phone number')
    } else if (contactMethod === 'email') {
        emailOrPhone = yup
            .string()
            .email('Please enter a valid email address')
            .required('Please enter your email address')
    }

    if (useType === '' && useTypeRequired) {
        useTypeErr = yup
            .string()
            .typeError('Please select your use type')
            .required('Please select your use type')
    }

    if (urgency === '') {
        urgencyErr = yup
            .string()
            .typeError('Please select a level of urgency')
            .required('Please select a level of urgency')
    }

    if (referralSource) {
        referralSourceErr = yup
            .array()
            .of(yup.string())
            .typeError('Please select how you heard about us')
            .required('Please select how you heard about us')
    }

    phoneErrCheck = yup
        .string()
        .test(
            'phone-api-error',
            'Error from API: ' + phoneErrorFromApi,
            () => !phoneErrorFromApi,
        )

    emailErrCheck = yup
        .string()
        .test(
            'email-api-error',
            'Error from API: ' + emailErrorFromApi,
            () => !emailErrorFromApi,
        )

    phoneErrCheck = yup
        .string()
        .test(
            'phone-api-error',
            'Error from API: ' + phoneErrorFromApi,
            () => !phoneErrorFromApi,
        )

    emailErrCheck = yup
        .string()
        .test(
            'email-api-error',
            'Error from API: ' + emailErrorFromApi,
            () => !emailErrorFromApi,
        )

    return yup.object().shape({
        [contactMethod]: emailOrPhone,
        useTypeErr: useTypeErr,
        urgencyErr: urgencyErr,
        referralSourceErr: referralSourceErr,
        phoneErrCheck,
        emailErrCheck,
    })
}

export const AppliancesValidationSchema = yup.object({
    appliances: yup.array().of(
        yup.object().shape({
            name: yup.string().required('Please select an appliance'),
            quantity: yup
                .number()
                .typeError('Please enter a quantity')
                .required('Please enter a quantity')
                .min(1, 'Please enter a minimum of one quantity'),
            backup_hours: yup
                .number()
                .nullable()
                .transform((value, originalValue) =>
                    originalValue === '' || originalValue === 0
                        ? undefined
                        : value,
                )
                .min(1, 'Please enter at least 1 day time backup hour')
                .max(10, 'Day time use cannot exceed 10 hours'),
            backup_hours_night: yup
                .number()
                .nullable()
                .transform((value, originalValue) =>
                    originalValue === '' || originalValue === 0
                        ? undefined
                        : value,
                )
                .min(1, 'Please enter at least 1 night time backup hour')
                .max(14, 'Night time use cannot exceed 14 hours'),
            backup_hours_combined: yup
                .mixed()
                .test(
                    'validate_combined',
                    'Provide at least 1 daily backup hour',
                    function () {
                        const { backup_hours, backup_hours_night } = this.parent
                        return (
                            backup_hours !== undefined ||
                            backup_hours_night !== undefined
                        )
                    },
                ),
            powerRating: yup
                .string()
                .required('Please enter a valid power rating')
                .test(
                    'is-zero',
                    'Please enter a valid power rating',
                    value => !/^0+$/.test(value),
                ),
        }),
    ),
})

export const AppliancesValidationUpdatedSchema = yup.object({
    appliances: yup.array().of(
        yup.object().shape({
            name: yup.string().required('Please select an appliance'),
            quantity: yup
                .number()
                .typeError('Please enter a quantity')
                .required('Please enter a quantity')
                .min(1, 'Please enter a minimum of one quantity'),
            backup_hours: yup
                .number()
                .nullable()
                .transform((value, originalValue) =>
                    originalValue === '' || originalValue === 0
                        ? undefined
                        : value,
                )
                .min(1, 'Please enter at least 1 day time backup hour')
                .max(10, 'Day time use cannot exceed 10 hours'),
            backup_hours_night: yup
                .number()
                .nullable()
                .transform((value, originalValue) =>
                    originalValue === '' || originalValue === 0
                        ? undefined
                        : value,
                )
                .min(1, 'Please enter at least 1 night time backup hour')
                .max(14, 'Night time use cannot exceed 14 hours'),
            backup_hours_combined: yup
                .mixed()
                .test(
                    'validate_combined',
                    'Provide at least 1 daily backup hour',
                    function () {
                        const { backup_hours, backup_hours_night } = this.parent
                        return (
                            backup_hours !== undefined ||
                            backup_hours_night !== undefined
                        )
                    },
                ),
            power_rating: yup
                .string()
                .required('Please enter a valid power rating')
                .test(
                    'is-zero',
                    'Please enter a valid power rating',
                    value => !/^0+$/.test(value),
                ),
        }),
    ),
})

export const EditApplianceValidationSchemaSSE = yup.object().shape({
    location: yup.string().required('Please select a location'),
    paymentPlanDuration: yup
        .string()
        .required('Please select payment duration'),
    monthlyPayment: yup
        .number()
        .typeError('Please enter the amount you want to pay monthly')
        .required('Please enter the amount you want to pay monthly'),
    generatorSpend: yup
        .number()
        .typeError('Please enter the amount you currently spend on generator')
        .required('Please enter the amount you currently spend on generator'),
    electricityPerDay: yup
        .string()
        .required('Please enter your amount of daily electricity'),
    canMountSolar: yup.string().required('Please select an option'),
    reasonForSolar: yup.string().required('Please select a reason'),
    paymentModel: yup.string().required('This is required'),
})

export const alternativeRecommendationValidationSchema = next => {
    switch (next) {
        case false:
            return yup.object().shape({
                location: yup.string().required('Please select a location'),
                paymentPlanDuration: yup
                    .string()
                    .required('Please select payment duration'),
                knowsMonthlyGenCost: yup
                    .string()
                    .required('Please select an option'),
                reasonForSolar: yup.string().required('Please select a reason'),
            })
        case true:
            return yup.object().shape({
                canMountSolar: yup.string().required('Please select an option'),
                monthlyPayment: yup
                    .number()
                    .required(
                        'Please enter the amount you want to pay monthly',
                    ),
                paymentModel: yup.string().required('This is required'),
                electricityPerDay: yup
                    .string()
                    .required('Please enter your amount of daily electricity'),
                generatorSpend: yup.number().when('generatorUse', {
                    is: use => use === 'yes',
                    then: yup
                        .number()
                        .typeError(
                            'Please enter the amount you currently spend on generator',
                        )
                        .required(
                            'Please enter the amount you currently spend on generator',
                        ),
                    otherwise: yup.number().notRequired(),
                }),
            })
    }
}

export const updateUserValidationSchema = yup.object().shape({
    firstName: yup
        .string()
        .typeError('First name is required')
        .required('First name is required'),
    lastName: yup
        .string()
        .typeError('Last name is required')
        .required('Last name is required'),
    email: yup
        .string()
        .email('Enter a valid email address')
        .required('Please enter an email address'),
    phoneNumber: yup
        .string()
        .required('This field is required')
        .matches(phoneNumberRegex, 'Please enter a valid phone number'),
    // TO-DO: Remove this when role is no longer required permanently.
    // role: yup
    //     .string()
    //     .typeError('Select at least one role')
    //     .required('Select at least one role'),
    groups: yup.array().min(1, 'Select at least one group').required(),
})

export const attributeValidationSchema = yup.object().shape({
    name: yup.string().required('Attribute name is required'),
    field_type: yup.string().required('Attribute type is required'),
    units_of_measurement: yup
        .array()
        .min(1, 'Select at least one unit of measurement')
        .required(),
    options: yup
        .array()
        .min(1, 'Select at least one allowed option')
        .required(),
})

export const recommendationContextValidationSchema = yup.object().shape({
    description: yup.string().required('Description is required'),
    remark: yup.string().required('Remark is required'),
})

export const AddAppliancesValidationSchema = yup.object({
    appliances: yup.array().of(
        yup.object().shape({
            name: yup.string().required('Appliance name is required'),
            unit_of_measurement: yup
                .string()
                .required('Please enter a unit of measurement'),
            power_rating: yup.string().required('Please enter a power rating'),
        }),
    ),
})

export const AddCustomSolutionSchema = yup.object().shape({
    recommendation_remark: yup.string().required('Remark is required'),
    recommendation_note: yup
        .string()
        .test('not-only-spaces', 'Description must contain text', value => {
            return !!value && value.trim().length > 0
        })
        .min(10, 'Description must be at least 10 characters'),
})

export const useTypeSchema = yup.object().shape({
    useType: yup.string().required('Please select your use type to continue'),
    email: yup.string().when('$contact', {
        is: 'email',
        then: yup
            .string()
            .required('Email is required')
            .email('Enter a valid email address'),
        otherwise: yup.string().notRequired(),
    }),
    phone: yup.string().when('$contact', {
        is: 'phone',
        then: yup
            .string()
            .matches(phoneNumberRegex, 'Please enter a valid phone number'),
        otherwise: yup.string().notRequired(),
    }),
})

export const newUseTypeSchema = yup.object().shape({
    email: yup.string().when('$contact', {
        is: 'email',
        then: yup
            .string()
            .required('Email is required')
            .email('Enter a valid email address'),
        otherwise: yup.string().notRequired(),
    }),
    phone: yup.string().when('$contact', {
        is: 'phone',
        then: yup
            .string()
            .matches(phoneNumberRegex, 'Please enter a valid phone number'),
        otherwise: yup.string().notRequired(),
    }),
    customer_type: yup
        .string()
        .required('Please select your use type to continue'),
})

export const newOnboardingUseTypeSchema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    email: yup
        .string()
        .required('Email is required')
        .email('Enter a valid email address'),
    phone: yup
        .string()
        .matches(phoneNumberRegex, 'Please enter a valid phone number'),
    customer_type: yup
        .string()
        .required('Please select your use type to continue'),
})

export const otpSchema = yup.array().of(yup.string().length(1)).length(6)

export const continueLaterEmailSchema = yup.object().shape({
    email: yup
        .string()
        .required('Email is required')
        .email('Enter a valid email address'),
})

export const loginSchema = yup.object().shape({
    phoneNumber: yup.string().when('$method', {
        is: 'phone',
        then: yup
            .string()
            .matches(phoneNumberRegex, 'Please enter a valid phone number'),
        otherwise: yup.string().notRequired(),
    }),
    emailAddress: yup.string().when('$method', {
        is: 'email',
        then: yup
            .string()
            .required('Email is required')
            .email('Enter a valid email address'),
        otherwise: yup.string().notRequired(),
    }),
})

export const validateBusinessInfoKyc = yup.object().shape({
    businessNameVerified: yup
        .boolean()
        .oneOf([true], 'Please verify your registered business name'),
    businessName: yup.string().required('Please provide business name'),
    businessType: yup.string().required('Please select a business type'),
    businessIndustry: yup
        .string()
        .required('Please select a business industry'),
    businessRegistrationNumberVerified: yup
        .boolean()
        .oneOf([true], 'Please verify your business registration number'),
    cacNumber: yup
        .string()
        .matches(cacNumberRegex, 'Please provide a valid registration number')
        .required('Please provide business registration number'),
    numberOfYearsInOperation: yup
        .string()
        .matches(/^[0-9]*$/, 'Years in operation should be a number')
        .required('Please provide number of years in operation'),
    website: yup
        .string()
        .matches(
            websiteRegex,
            'Please provide a valid website format like "example.com" or "https://example.com"',
        ),
    businessDescription: yup.string().required('Please provide description'),
})

export const validateFinancialInfoKyc = yup.object().shape({
    bankName: yup.string().required('Please select your bank name.'),
    monthlyRevenue: yup
        .string()
        .required('Please provide your monthly revenue.'),
    paymentMethod: yup.string().required('Please select your payment method.'),
})

export const validateBusinessContactInfoKyc = yup.object().shape({
    firstName: yup.string().required('Please provide first name'),
    lastName: yup.string().required('Please provide last name'),
    phone: yup
        .string()
        .matches(phoneNumberRegex, 'Please enter a valid phone number')
        .required('Please provide your phone number'),
    phone_verified: yup
        .boolean()
        .oneOf([true], 'Please verify your phone number'),
    email: yup
        .string()
        .email('Enter a valid email address')
        .required('Please provide email'),
    email_verified: yup
        .boolean()
        .oneOf([true], 'Please verify your email address'),
    directorsFirstName: yup
        .string()
        .required(`Please provide director's first name`),
    directorsLastName: yup
        .string()
        .required(`Please provide director's last name`),
    directorsPhoneNumber: yup
        .string()
        .matches(
            phoneNumberRegex,
            `Please enter your director's valid phone number`,
        )
        .required("Please provide your director's phone number"),
    directorsEmail: yup
        .string()
        .email(`Please provide your valid director's email address`)
        .required(`Please provide your director's email address`),
    businessAddress: yup
        .string()
        .required('Please provide your business address'),
    businessCity: yup.string().required('Please provide your city'),
    businessState: yup.string().required('Please provide your state'),
    businessCountry: yup.string().required('Please provide your country'),
    confirmAddress: yup.string().required('Please select an option'),
    installationAddress: yup.string().when('confirmAddress', {
        is: confirmAddress =>
            confirmAddress === 'yes' || confirmAddress === 'no',
        then: yup.string().required('Please provide your installation address'),
        otherwise: yup.string().notRequired(),
    }),

    installationCity: yup.string().when('confirmAddress', {
        is: confirmAddress =>
            confirmAddress === 'yes' || confirmAddress === 'no',
        then: yup.string().required('Please provide your installation city'),
        otherwise: yup.string().notRequired(),
    }),

    installationState: yup.string().when('confirmAddress', {
        is: confirmAddress =>
            confirmAddress === 'yes' || confirmAddress === 'no',
        then: yup.string().required('Please provide your installation state'),
        otherwise: yup.string().notRequired(),
    }),

    installationCountry: yup.string().when('confirmAddress', {
        is: confirmAddress =>
            confirmAddress === 'yes' || confirmAddress === 'no',
        then: yup.string().required('Please provide your installation country'),
        otherwise: yup.string().notRequired(),
    }),
})

export const NewLandingPageFormSchema = (errMsgs = {}) =>
    yup.object().shape({
        firstName: yup.string().when('$fields', {
            is: fieldsArray => fieldsArray.includes('firstName'),
            then: yup
                .string()
                .required('First name is required')
                .matches(
                    validateNameRegex,
                    errMsgs?.firstName || 'Please enter a valid first name',
                )
                .matches(singleNameRegex, 'Please enter your first name only'),
            otherwise: yup.string().notRequired(),
        }),
        phone: yup.string().when('$fields', {
            is: fieldsArray => fieldsArray.includes('phone'),
            then: yup
                .string()
                .required('Phone number is required')
                .matches(
                    phoneNumberRegex,
                    errMsgs?.phone || 'Please enter a valid phone number',
                ),
            otherwise: yup.string().notRequired(),
        }),
        email: yup.string().when('$fields', {
            is: fieldsArray => fieldsArray.includes('email'),
            then: yup
                .string()
                .required('Email is required')
                .email('Please enter a valid email address')
                .matches(
                    emailRegex,
                    errMsgs?.email || 'Please enter a valid email address',
                ),
            otherwise: yup.string().notRequired(),
        }),
        urgency: yup.string().when('$fields', {
            is: fieldsArray => fieldsArray.includes('urgency'),
            then: yup
                .string()
                .required(
                    errMsgs?.urgency || 'Please select a level of urgency',
                ),
            otherwise: yup.string().notRequired(),
        }),
        referral_source: yup.array().when('$fields', {
            is: fieldsArray => fieldsArray.includes('referral_source'),
            then: yup
                .array()
                .of(yup.string())
                .min(
                    1,
                    errMsgs?.referral_source ||
                        'Please select at least one option',
                )
                .required(
                    errMsgs?.referral_source ||
                        'Please select how you heard about us',
                ),
            otherwise: yup.array().notRequired(),
        }),
        useType: yup.string().when('$fields', {
            is: fieldsArray => fieldsArray.includes('useType'),
            then: yup
                .string()
                .required(errMsgs?.useType || 'Please select your use type'),
            otherwise: yup.string().notRequired(),
        }),
    })

export const businessVerificationSchema = yup.object().shape({
    cac: yup
        .array()
        .min(1, 'CAC document is required')
        .required('CAC document is required'),
    moa: yup
        .array()
        .min(1, 'MOA document is required')
        .required('MOA document is required'),
    bvn: yup.string().when('bvn_verified', {
        is: false,
        then: yup
            .string()
            .matches(bvnRegex, 'Please provide a valid BVN')
            .required('Please provide a valid BVN'),
        otherwise: yup.string().notRequired(),
    }),
    bvn_verified: yup.bool().oneOf([true], 'BVN must be verified'),
})

export const validatePersonalInfoKyc = yup.object().shape({
    firstName: yup
        .string()
        .required('Please provide your first name')
        .matches(validateNameRegex, 'Please enter a valid first name'),
    lastName: yup
        .string()
        .required('Please provide your surname')
        .matches(validateNameRegex, 'Please enter a valid surname'),
    phone: yup
        .string()
        .required('Please provide your phone number')
        .matches(phoneNumberRegex, 'Please enter a valid phone number'),
    phoneVerified: yup
        .boolean()
        .oneOf([true], 'Please verify your phone number'),
    email: yup
        .string()
        .required('Please provide your email address')
        .email('Please provide a valid email address'),
    emailVerified: yup
        .boolean()
        .oneOf([true], 'Please verify your email address'),
    contactAddress: yup
        .string()
        .required('Please provide your contact address'),
    contactCity: yup.string().required('Please select city'),
    contactState: yup.string().required('Please select state'),
    contactCountry: yup.string().required('Please select country'),
    confirmAddress: yup.string().required('Please select an option'),
    installationAddress: yup.string().when('confirmAddress', {
        is: confirmAddress =>
            confirmAddress === 'yes' || confirmAddress === 'no',
        then: yup.string().required('Please provide your installation address'),
        otherwise: yup.string().notRequired(),
    }),

    installationCity: yup.string().when('confirmAddress', {
        is: confirmAddress =>
            confirmAddress === 'yes' || confirmAddress === 'no',
        then: yup.string().required('Please select city'),
        otherwise: yup.string().notRequired(),
    }),

    installationState: yup.string().when('confirmAddress', {
        is: confirmAddress =>
            confirmAddress === 'yes' || confirmAddress === 'no',
        then: yup.string().required('Please select state'),
        otherwise: yup.string().notRequired(),
    }),

    installationCountry: yup.string().when('confirmAddress', {
        is: confirmAddress =>
            confirmAddress === 'yes' || confirmAddress === 'no',
        then: yup.string().required('Please select country'),
        otherwise: yup.string().notRequired(),
    }),
})

export const validateOutrightSaleInfoKyc = yup.object().shape({
    firstName: yup
        .string()
        .required('Please provide your first name')
        .matches(validateNameRegex, 'Please enter a valid first name'),
    lastName: yup
        .string()
        .required('Please provide your surname')
        .matches(validateNameRegex, 'Please enter a valid surname'),
    phone: yup
        .string()
        .required('Please provide your phone number')
        .matches(phoneNumberRegex, 'Please enter a valid phone number'),
    phoneVerified: yup
        .boolean()
        .oneOf([true], 'Please verify your phone number'),
    email: yup
        .string()
        .required('Please provide your email address')
        .email('Please provide a valid email address'),
    emailVerified: yup
        .boolean()
        .oneOf([true], 'Please verify your email address'),
    contactAddress: yup
        .string()
        .required('Please provide your contact address'),
    contactCity: yup.string().required('Please select city'),
    contactState: yup.string().required('Please select state'),
    contactCountry: yup.string().required('Please select country'),
    installationAddress: yup.string().when('$sameAddress', {
        is: false,
        then: yup.string().required('Please provide your installation address'),
    }),
    installationCountry: yup.string().when('$sameAddress', {
        is: false,
        then: yup.string().required('Please select country'),
    }),
    installationState: yup.string().when('$sameAddress', {
        is: false,
        then: yup.string().required('Please select state'),
    }),
    installationCity: yup.string().when('$sameAddress', {
        is: false,
        then: yup.string().required('Please select city'),
    }),
    businessName: yup.string().when('$userType', {
        is: 'BUSINESS',
        then: yup.string().required('Please provide your business name'),
    }),
    businessType: yup.string().when('$userType', {
        is: 'BUSINESS',
        then: yup.string().required('Please provide your business type'),
    }),
    businessIndustry: yup.string().when('$userType', {
        is: 'BUSINESS',
        then: yup.string().required('Please provide your business industry'),
    }),
})

export const validateEmploymentInfoKyc = yup.object().shape({
    employmentType: yup.string().required('Please select an employment type'),
    companyName: yup.string().when('employmentType', {
        is: val => ['', 'Full Time', 'Part Time', 'Contract'].includes(val),
        then: yup.string().required('Please provide your company name'),
    }),
    jobTitle: yup.string().when('employmentType', {
        is: val => ['', 'Full Time', 'Part Time', 'Contract'].includes(val),
        then: yup.string().required('Please provide your job title'),
    }),
    jobStartDate: yup.string().when('employmentType', {
        is: val => ['', 'Full Time', 'Part Time', 'Contract'].includes(val),
        then: yup.string().required('Please provide your job start date'),
    }),
    pensionManager: yup.string().when('employmentType', {
        is: 'Retired',
        then: yup.string().required('Please select a pension manager'),
    }),
    retirementDate: yup.string().when('employmentType', {
        is: 'Retired',
        then: yup.string().required('Please select your retirement date'),
    }),
    businessName: yup
        .string()
        .when(['employmentType', 'isBusinessRegistered'], {
            is: (eT, bizReg) => eT === 'Self Employed' && bizReg === true,
            then: yup.string().required('Please provide your business name'),
        }),
    businessNameVerified: yup.boolean().when('employmentType', {
        is: (eT, bizReg) => eT === 'Self Employed' && bizReg === true,
        then: yup
            .boolean()
            .oneOf(
                [true],
                'Please provide registered business name for the CAC number inputed',
            ),
    }),
    businessType: yup.string().when('employmentType', {
        is: 'Self Employed',
        then: yup.string().required('Please select a business type'),
    }),
    businessIndustry: yup.string().when('employmentType', {
        is: 'Self Employed',
        then: yup.string().required('Please select a business industry'),
    }),
    cacNumber: yup.string().when(['employmentType', 'isBusinessRegistered'], {
        is: (eT, bizReg) => eT === 'Self Employed' && bizReg === true,
        then: yup
            .string()
            .required('Please provide business registration number'),
    }),
    cacNumberVerified: yup
        .boolean()
        .when(['employmentType', 'isBusinessRegistered'], {
            is: (eT, bizReg) => eT === 'Self Employed' && bizReg === true,
            then: yup
                .boolean()
                .oneOf(
                    [true],
                    'Please verify your business registration number',
                ),
        }),
    numberOfYearsInOperation: yup.string().when('employmentType', {
        is: 'Self Employed',
        then: yup
            .string()
            .required('Please provide number of years in operation'),
    }),
    businessDescription: yup.string().when('employmentType', {
        is: 'Self Employed',
        then: yup.string().required('Please provide description'),
    }),
    linkedinPage: yup.string().when('employmentType', {
        is: val =>
            ['', 'Full Time', 'Part Time', 'Contract', 'Retired'].includes(val),
        then: yup
            .string()
            .nullable()
            .transform((v, o) => {
                return o === '' ? null : v
            })
            .matches(websiteRegex, 'Please provide a valid LinkedIn url'),
    }),
    website: yup.string().when('employmentType', {
        is: 'Self Employed',
        then: yup
            .string()
            .nullable()
            .transform((v, o) => {
                return o === '' ? null : v
            })
            .matches(
                websiteRegex,
                'Please provide a valid website format like "example.com" or "https://example.com"',
            ),
    }),
})

export const validateIdentityInfoKyc = yup.object().shape({
    imageUrl: yup.string().when('button', {
        is: val => ['Submit', 'Compare'].includes(val),
        then: yup.string().required('Please upload an image'),
        otherwise: yup.string().nullable(),
    }),
    firstName: yup.string().when('button', {
        is: 'Submit',
        then: yup.string().required('Please provide first name'),
        otherwise: yup.string().nullable(),
    }),
    lastName: yup.string().when('button', {
        is: 'Submit',
        then: yup.string().required('Please provide last name'),
        otherwise: yup.string().nullable(),
    }),
    identityType: yup.string().required('Please select an identity type'),
    identityVerified: yup.boolean().when('button', {
        is: 'Compare',
        then: yup.boolean().oneOf([true], 'Please verify your identity number'),
        otherwise: yup.boolean().nullable(),
    }),
    date_of_birth: yup
        .string()
        .nullable()
        .when('identityType', {
            is: val => (val === 'Drivers License' ? true : false),
            then: yup.string().required('Date of Birth is Required'),
            otherwise: yup.string().notRequired(),
        }),
    identityNumber: yup
        .string()
        .required('Please enter your identity number')
        .when('identityType', {
            is: val => {
                return val === 'Voters Card' ? true : false
            },
            then: yup
                .string()
                .required("Please enter your voter's card number")
                .matches(
                    VotersCardRegex,
                    'Voter’s Card Number should be 19 characters',
                ),
        })
        .when('identityType', {
            is: val =>
                val === 'Virtual National Identification Number' ? true : false,
            then: yup
                .string()
                .required('Please enter your Virtual NIN')
                .min(
                    16,
                    'Your Virtual NIN should be 16 alphanumeric characters',
                )
                .max(
                    16,
                    'Your Virtual NIN should be 16 alphanumeric characters',
                ),
        })
        .when('identityType', {
            is: val => (val === 'Drivers License' ? true : false),
            then: yup
                .string()
                .required("Please enter your driver's license number")
                .matches(
                    DriversLicenseRegex,
                    'Drivers License number is wrong',
                ),
        })
        .when('identityType', {
            is: val =>
                val === 'National Identification Number' ? true : false,
            then: yup
                .string()
                .required('Please enter your NIN')
                .min(11, 'Your NIN should be 11 digits')
                .max(11, 'Your NIN should be 11 digits'),
        })
        .when('identityType', {
            is: val => (val === 'International Passport' ? true : false),
            then: yup
                .string()
                .required('Please enter your international passport number')
                .matches(
                    InternationalPassportRegex,
                    'International Passport number is wrong',
                ),
        }),
})

export const updateRepaymentSetupSchema = yup.object().shape({
    repaymentAction: yup.string().required('Select what you want to update'),
    newRepaymentDate: yup.date().when('repaymentAction', {
        is: 'Change repayment date',
        then: yup
            .date()
            .required('New repayment date is required')
            .typeError('Invalid date'),
    }),
    repaymentMethod: yup.string().when('repaymentAction', {
        is: 'Select new repayment method',
        then: yup.string().required('Select repayment method'),
    }),
    subscriptionCode: yup.string().when('repaymentMethod', {
        is: 'Paystack plan subscription',
        then: yup
            .string()
            .required('Subscription code is required')
            .matches(
                paystackSubscriptionCodeRegex,
                'Invalid subscription code',
            ),
    }),
    repayment_method_setup_file: yup.array().when('repaymentMethod', {
        is: 'Bank transfer (Standing order)',
        then: yup.array().min(1, 'Please upload file'),
    }),
})

export const updateRepaymentByCustomerSchema = yup.object().shape({
    whoToUpdate: yup
        .string()
        .required('Select how you want to update repayment'),
    notes: yup.string().when('whoToUpdate', {
        is: 'Update done by consumer',
        then: yup.string().required('Notes is required'),
    }),
})

export const disputePackageSchema = yup.object().shape({
    category: yup.string().required('Please select a category'),
    reason: yup.string().required('Please provide a reason'),
})

export const requestUpdateSchema = yup.object().shape({
    title: yup.string().required('Please provide a title'),
    fields: yup.array().min(1, 'Select at least one field').required(),
    notes: yup.string().required('Please provide a note for the consumer'),
})

export const manualRecoValidationSchema = yup.object().shape({
    monthlyPayment: yup
        .number()
        .required('Please enter the amount you want to pay monthly'),
    generatorSpend: yup.number().required('Please enter your generator spend'),
})

// this would be used when backend update on generator size is used
export const manualRecoValidationSchema2 = yup.object().shape({
    monthlyPayment: yup
        .number()
        .moreThan(0, 'Monthly payment must be greater than 0'),
    generatorSpend: yup.number().when('knowsGenMonthlyCost', {
        is: 'yes',
        then: yup
            .number()
            .moreThan(0, 'Generator spend must be greater than 0'),
    }),
    generatorSize: yup.string().when('knowsGenMonthlyCost', {
        is: 'no',
        then: yup.string().required('Please select generator size'),
    }),
    generatorMaintenance: yup.string().when('knowsGenMonthlyCost', {
        is: 'no',
        then: yup.string().required('Please select generator maintenance'),
    }),
})

export const additionalFieldsValidator = yup.object().shape({
    firstName: yup
        .string()
        .required('First name is required')
        .matches(nameRegex, 'Use only letters here'),
    lastName: yup
        .string()
        .required('Last name is required')
        .matches(nameRegex, 'Use only letters here'),
    email: yup
        .string()
        .required('Please provide your email address')
        .email('Please provide a valid email address'),
    phone: yup
        .string()
        .transform((value, originalValue) =>
            originalValue === '' ? null : value,
        )
        .nullable()
        .matches(phoneNumberRegex, 'Please enter a valid phone number'),
})

export const PackageProtectiveDeviceValidationSchema = yup.object({
    inputs: yup.array().of(
        yup.object().shape({
            name: yup.string().required('Please select a protective device'),
            category: yup.string().required('Please select a description'),
            unit_of_measurement: yup
                .string()
                .required('Please select a unit of measurement'),
            rating: yup.string().required('Please enter a rating'),
            quantity: yup.string().required('Please enter a quantity'),
        }),
    ),
})

export const CreateProfileFieldsValidator = yup.object().shape({
    name: yup.string().required('Consumer profile name is required'),
    description: yup
        .string()
        .test(
            'required-check',
            'Consumer profile description is required',
            function (value) {
                const cleanValue = replaceHtmlTags(value)
                return cleanValue.length > 2
            },
        )
        .test('length-check', 'Character limit exceeded', function (value) {
            const cleanValue = replaceHtmlTags(value)
            return cleanValue.length <= 250
        }),
    customer_type: yup.string().required('Consumer profile type is required'),
    min_capacity: yup.string().required('Minimum system size is required'),
    max_capacity: yup
        .string()
        .required('Maximum system size is required')
        .test(
            'max-greater-than-min',
            'Maximum capacity must be greater or equal to minimum capacity',
            function (value) {
                const minCapacity = JSON.parse(this.parent.min_capacity)
                const maxCapacity = JSON.parse(value)
                if (
                    typeof minCapacity?.value === 'number' &&
                    typeof maxCapacity?.value === 'number'
                ) {
                    return maxCapacity.value >= minCapacity.value
                }
            },
        ),
    appliances: yup.array().min(1, 'Add at least one appliance').required(),
})

export const CreateProfileAdditionalFieldsValidator = yup.object().shape({
    image_url: yup.string().required('Consumer profile image is required'),
    group_id: yup.string().required('Consumer profile group is required'),
    sort_by: yup.string().required('Consumer profile ordering is required'),
})

export const verifyContactSchema = yup.object().shape({
    phone: yup.string().when('$type', {
        is: 'phone',
        then: yup
            .string()
            .matches(phoneNumberRegex, 'Please enter a valid phone number'),
        otherwise: yup.string().notRequired(),
    }),
    email: yup.string().when('$type', {
        is: 'email',
        then: yup
            .string()
            .required('Email is required')
            .email('Please enter a valid email address'),
        otherwise: yup.string().notRequired(),
    }),
})

export const profileGroupSchema = yup.object().shape({
    name: yup.string().required('Please provide a profile group name'),
    customer_type: yup.string().required('Please select a profile group type'),
    profile_sorting: yup
        .string()
        .required('Please select a profile sorting type'),
})

export const createDynamicComponentsSchema = componentTypes => {
    let schemaFields = {}

    componentTypes?.forEach(type => {
        schemaFields[type] = yup
            .array()
            .of(yup.string().required('Brand name not valid'))
            .min(1, 'Add at least one brand')
            .required('Add at least one brand')
    })

    return yup.object().shape(schemaFields)
}

export const editBrandNameSchema = yup.array().of(
    yup.object().shape({
        name: yup.string().required('Name is required'),
    }),
)

export const validateClusterMemberInfo = yup.object().shape({
    first_name: yup.string().when('$personalInfoForm', {
        is: true,
        then: yup
            .string()
            .required("Please provide the member's first name")
            .matches(validateNameRegex, 'Please enter a valid first name'),
        otherwise: yup.string(),
    }),
    last_name: yup.string().when('$personalInfoForm', {
        is: true,
        then: yup
            .string()
            .required("Please provide the member's surname")
            .matches(validateNameRegex, 'Please enter a valid surname'),
        otherwise: yup.string(),
    }),
    phone_number: yup.string().when('$personalInfoForm', {
        is: true,
        then: yup
            .string()
            .required("Please provide the member's phone number")
            .matches(phoneNumberRegex, 'Please enter a valid phone number'),
        otherwise: yup.string(),
    }),
    email: yup.string().when('$personalInfoForm', {
        is: true,
        then: yup
            .string()
            .required("Please provide the member's work email address")
            .email('Please provide a valid work email address'),
        otherwise: yup.string(),
    }),
    idCardType: yup.string().when('$idInfoForm', {
        is: true,
        then: yup.string().required("Please provide the member's ID card type"),
        otherwise: yup.string(),
    }),
    idCardNo: yup.string().when('$idInfoForm', {
        is: true,
        then: yup
            .string()
            .required("Please provide the member's ID card number")
            .when('$idCardType', {
                is: val => {
                    return val === 'Voters Card' ? true : false
                },
                then: yup
                    .string()
                    .required("Please enter the member's voter's card number")
                    .matches(
                        VotersCardRegex,
                        'Voter’s Card Number should be 19 characters',
                    ),
            })
            .when('$idCardType', {
                is: val =>
                    val === 'Virtual National Identification Number'
                        ? true
                        : false,
                then: yup
                    .string()
                    .required("Please enter the member's Virtual NIN")
                    .min(16, 'Virtual NIN should be 16 alphanumeric characters')
                    .max(
                        16,
                        'Virtual NIN should be 16 alphanumeric characters',
                    ),
            })
            .when('$idCardType', {
                is: val => (val === 'Drivers License' ? true : false),
                then: yup
                    .string()
                    .required(
                        "Please enter the member's driver's license number",
                    )
                    .matches(
                        DriversLicenseRegex,
                        'Drivers License number is wrong',
                    ),
            })
            .when('$idCardType', {
                is: val =>
                    val === 'National Identification Number' ? true : false,
                then: yup
                    .string()
                    .required('Please enter your NIN')
                    .min(11, 'NIN should be 11 digits')
                    .max(11, 'NIN should be 11 digits'),
            })
            .when('$idCardType', {
                is: val => (val === 'International Passport' ? true : false),
                then: yup
                    .string()
                    .required(
                        "Please enter the member's international passport number",
                    )
                    .matches(
                        InternationalPassportRegex,
                        'International Passport number is wrong',
                    ),
            }),
        otherwise: yup.string(),
    }),
})
export const ProfileClusterInfoValidator = yup.object().shape({
    name: yup.string().required('Cluster name is required').nullable(),
    website: yup.string().nullable(),
    logo: yup.string().required('Cluster logo is required').nullable(),
    representatives: yup
        .array()
        .nullable()
        .when('cluster_representative', {
            is: true,
            then: yup
                .array()
                .min(1, 'At least one representative is required')
                .required('Representatives are required'),
            otherwise: yup.array().notRequired(),
        }),
})

export const ProfileClusterRepValidator = yup.object().shape({
    first_name: yup.string().required('Representative first name is required'),
    last_name: yup.string().required('Representative last name is required'),
    email: yup.string().email().required('Representative email is required'),
    phone: yup
        .string()
        .required('Representative phone number is required')
        .matches(phoneNumberRegex, 'Please enter a valid phone number'),
})

export const validateDynamicKycInfoKyc = yup.object().shape({
    firstName: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'personal_information' &&
            requiredKycSections?.includes('full_name'),
        then: yup
            .string()
            .required('Please provide your first name')
            .matches(validateNameRegex, 'Please enter a valid first name'),
    }),
    lastName: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'personal_information' &&
            requiredKycSections?.includes('full_name'),
        then: yup
            .string()
            .required('Please provide your surname')
            .matches(validateNameRegex, 'Please enter a valid surname'),
    }),
    phone: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'personal_information' &&
            requiredKycSections?.includes('phone_number_email'),
        then: yup
            .string()
            .required('Please provide your phone number')
            .matches(phoneNumberRegex, 'Please enter a valid phone number'),
    }),
    phoneVerified: yup.boolean().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'personal_information' &&
            requiredKycSections?.includes('phone_number_email'),
        then: yup.boolean().oneOf([true], 'Please verify your phone number'),
    }),
    email: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'personal_information' &&
            requiredKycSections?.includes('phone_number_email'),
        then: yup
            .string()
            .required('Please provide your email address')
            .email('Please provide a valid email address'),
    }),
    emailVerified: yup.boolean().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'personal_information' &&
            requiredKycSections?.includes('phone_number_email'),
        then: yup.boolean().oneOf([true], 'Please verify your email address'),
    }),
    contactAddress: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'personal_information' &&
            requiredKycSections?.includes('address'),
        then: yup.string().required('Please provide your contact address'),
    }),
    contactCity: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'personal_information' &&
            requiredKycSections?.includes('address'),
        then: yup.string().required('Please select city'),
    }),
    contactState: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'personal_information' &&
            requiredKycSections?.includes('address'),
        then: yup.string().required('Please select state'),
    }),
    contactCountry: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'personal_information' &&
            requiredKycSections?.includes('address'),
        then: yup.string().required('Please select country'),
    }),
    installationAddress: yup
        .string()
        .when(['$moduleKey', '$requiredKycSections', '$sameAddress'], {
            is: (moduleKey, requiredKycSections, sameAddress) =>
                moduleKey === 'personal_information' &&
                requiredKycSections?.includes('address') &&
                sameAddress === false,
            then: yup
                .string()
                .required('Please provide your installation address'),
        }),
    installationCountry: yup
        .string()
        .when(['$moduleKey', '$requiredKycSections', '$sameAddress'], {
            is: (moduleKey, requiredKycSections, sameAddress) =>
                moduleKey === 'personal_information' &&
                requiredKycSections?.includes('address') &&
                sameAddress === false,
            then: yup.string().required('Please select country'),
        }),
    installationState: yup
        .string()
        .when(['$moduleKey', '$requiredKycSections', '$sameAddress'], {
            is: (moduleKey, requiredKycSections, sameAddress) =>
                moduleKey === 'personal_information' &&
                requiredKycSections?.includes('address') &&
                sameAddress === false,
            then: yup.string().required('Please select state'),
        }),
    installationCity: yup
        .string()
        .when(['$moduleKey', '$requiredKycSections', '$sameAddress'], {
            is: (moduleKey, requiredKycSections, sameAddress) =>
                moduleKey === 'personal_information' &&
                requiredKycSections?.includes('address') &&
                sameAddress === false,
            then: yup.string().required('Please select city'),
        }),
    employmentType: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'employment_information' &&
            requiredKycSections?.includes('employment_type'),
        then: yup.string().required('Please select employment type'),
    }),
    companyName: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'employment_information' &&
            requiredKycSections?.includes('work_info'),
        then: yup.string().required('Please provide your company name'),
    }),
    jobTitle: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'employment_information' &&
            requiredKycSections?.includes('work_info'),
        then: yup.string().required('Please provide your job title'),
    }),
    jobStartDate: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'employment_information' &&
            requiredKycSections?.includes('employment_details'),
        then: yup.string().required('Please provide your job start date'),
    }),
    linkedinPage: yup
        .string()
        .nullable()
        .transform((v, o) => {
            return o === '' ? null : v
        })
        .when(['$moduleKey', '$requiredKycSections'], {
            is: (moduleKey, requiredKycSections) =>
                moduleKey === 'employment_information' &&
                (requiredKycSections?.includes('employment_details') ||
                    requiredKycSections?.includes('pension_details')),
            then: yup
                .string()
                .nullable()
                .test(
                    'is-url-valid',
                    'Please provide a valid LinkedIn URL',
                    value => !value || websiteRegex.test(value),
                ),
        }),
    netMonthlySalary: yup
        .string()
        .when(['$moduleKey', '$requiredKycSections'], {
            is: (moduleKey, requiredKycSections) =>
                moduleKey === 'employment_information' &&
                requiredKycSections?.includes('employee_data'),
            then: yup
                .string()
                .required('Please provide your net monthly salary'),
        }),
    employeeId: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'employment_information' &&
            requiredKycSections?.includes('employee_data'),
        then: yup.string().required('Please provide your employee ID'),
    }),
    businessName: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'employment_information' &&
            requiredKycSections?.includes('business'),
        then: yup.string().required('Please provide your business name'),
    }),
    businessIndustry: yup
        .string()
        .when(['$moduleKey', '$requiredKycSections'], {
            is: (moduleKey, requiredKycSections) =>
                moduleKey === 'employment_information' &&
                requiredKycSections?.includes('business'),
            then: yup.string().required('Please select your business industry'),
        }),
    cacNumber: yup
        .string()
        .when(['$moduleKey', '$requiredKycSections', '$isRegisteredBusiness'], {
            is: (moduleKey, requiredKycSections, isRegisteredBusiness) =>
                moduleKey === 'employment_information' &&
                requiredKycSections?.includes('business') &&
                isRegisteredBusiness === true,
            then: yup
                .string()
                .required('Please provide your business registration number')
                .matches(
                    cacNumberRegex,
                    'Please provide a valid registration number',
                ),
        }),
    cacNumberVerified: yup
        .boolean()
        .when(['$moduleKey', '$requiredKycSections', '$isRegisteredBusiness'], {
            is: (moduleKey, requiredKycSections, isRegisteredBusiness) =>
                moduleKey === 'employment_information' &&
                requiredKycSections?.includes('business') &&
                isRegisteredBusiness === true,
            then: yup.boolean().oneOf([true], 'Please verify your CAC number'),
        }),
    numberOfYearsInOperation: yup
        .string()
        .when(['$moduleKey', '$requiredKycSections'], {
            is: (moduleKey, requiredKycSections) =>
                moduleKey === 'employment_information' &&
                requiredKycSections?.includes('business'),
            then: yup
                .string()
                .required('Please enter your number of years in operation'),
        }),
    businessWebsite: yup
        .string()
        .nullable()
        .transform((v, o) => {
            return o === '' ? null : v
        })
        .when(['$moduleKey', '$requiredKycSections'], {
            is: (moduleKey, requiredKycSections) =>
                moduleKey === 'employment_information' &&
                requiredKycSections?.includes('business'),
            then: yup
                .string()
                .nullable()
                .test(
                    'is-url-valid',
                    'Please provide a valid website URL',
                    value => !value || websiteRegex.test(value),
                ),
        }),
    businessDescription: yup
        .string()
        .when(['$moduleKey', '$requiredKycSections'], {
            is: (moduleKey, requiredKycSections) =>
                moduleKey === 'employment_information' &&
                requiredKycSections?.includes('business'),
            then: yup
                .string()
                .required('Please provide a description of your business'),
        }),
    pensionManager: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'employment_information' &&
            requiredKycSections?.includes('pension_details'),
        then: yup.string().required('Please select your pension manager'),
    }),
    retirementDate: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'employment_information' &&
            requiredKycSections?.includes('pension_details'),
        then: yup.string().required('Please select your retirement date'),
    }),
    selfieImageUrl: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'identity_information' &&
            requiredKycSections?.includes('avatar'),
        then: yup.string().required('Please upload an image of yourself'),
    }),
    identityType: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'identity_information' &&
            requiredKycSections?.includes('id_card'),
        then: yup.string().required('Please select your ID card type'),
    }),
    identityNumber: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'identity_information' &&
            requiredKycSections?.includes('id_card'),
        then: yup
            .string()
            .required('Please enter your ID card number')
            .when('$identityType', {
                is: val => {
                    return val === 'Voters Card' ? true : false
                },
                then: yup
                    .string()
                    .required("Please enter your voter's card number")
                    .matches(
                        VotersCardRegex,
                        'Voter’s Card Number should be 19 characters',
                    ),
            })
            .when('$identityType', {
                is: val =>
                    val === 'Virtual National Identification Number'
                        ? true
                        : false,
                then: yup
                    .string()
                    .required('Please enter your Virtual NIN')
                    .min(
                        16,
                        'Your Virtual NIN should be 16 alphanumeric characters',
                    )
                    .max(
                        16,
                        'Your Virtual NIN should be 16 alphanumeric characters',
                    ),
            })
            .when('$identityType', {
                is: val => (val === 'Drivers License' ? true : false),
                then: yup
                    .string()
                    .required("Please enter your driver's license number")
                    .matches(
                        DriversLicenseRegex,
                        'Drivers License number is wrong',
                    ),
            })
            .when('$identityType', {
                is: val =>
                    val === 'National Identification Number' ? true : false,
                then: yup
                    .string()
                    .required('Please enter your NIN')
                    .min(11, 'Your NIN should be 11 digits')
                    .max(11, 'Your NIN should be 11 digits'),
            })
            .when('$identityType', {
                is: val => (val === 'International Passport' ? true : false),
                then: yup
                    .string()
                    .required('Please enter your international passport number')
                    .matches(
                        InternationalPassportRegex,
                        'International Passport number is wrong',
                    ),
            }),
    }),
    bvn: yup
        .string()
        .when(['$moduleKey', '$requiredKycSections', '$bvnVerified'], {
            is: (moduleKey, requiredKycSections, bvnVerified) =>
                moduleKey === 'financial_information' &&
                requiredKycSections?.includes('bvn') &&
                !bvnVerified,
            then: yup
                .string()
                .required('Please enter your BVN')
                .matches(bvnRegex, 'Please provide a valid BVN'),
        }),
    bvnVerified: yup.boolean().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'financial_information' &&
            requiredKycSections?.includes('bvn'),
        then: yup.boolean().oneOf([true], 'Please verify your BVN'),
    }),
    repaymentMethod: yup.string().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'financial_information' &&
            requiredKycSections?.includes('repayment_method'),
        then: yup.string().required('Please select a repayment method'),
    }),
    bankStatements: yup.array().when(['$moduleKey', '$requiredKycSections'], {
        is: (moduleKey, requiredKycSections) =>
            moduleKey === 'financial_information' &&
            requiredKycSections?.includes('bank_statement'),
        then: yup
            .array()
            .min(1, 'Please upload your bank statement')
            .required(),
        otherwise: yup.array().notRequired(),
    }),
})

export const validateCacDetails = yup.object().shape({
    businessName: yup.string().required('Please provide your business name'),
    cacNumber: yup
        .string()
        .required('Please provide business registration number')
        .matches(cacNumberRegex, 'Please provide a valid registration number'),
})

export const manualReviewSchema = yup.object().shape({
    documentType: yup.string().when('$categoryId', {
        is: 0,
        then: yup.string().required('Select document type'),
    }),
    document: yup.mixed().when('$categoryId', {
        is: 0,
        then: yup
            .mixed()
            .required('Document is required')
            .test('fileExists', 'Document is required', value => {
                return value && value.length > 0
            }),
    }),
    note: yup.string().when('$categoryId', {
        is: 0,
        then: yup.string().required('Please provide a note'),
    }),
    decision: yup.string().when('$categoryId', {
        is: 1,
        then: yup.string().required('Please select a decision'),
    }),
    tenure: yup.string().when('$categoryId', {
        is: 1,
        then: yup.string().required('Please select a tenure'),
    }),
    reason: yup.string().when('$categoryId', {
        is: 2,
        then: yup.string().required('Please enter reason'),
    }),
})

export const validateIdCardDetails = yup.object().shape({
    firstName: yup
        .string()
        .required('Please provide your first name')
        .matches(validateNameRegex, 'Please enter a valid first name'),
    lastName: yup
        .string()
        .required('Please provide your surname')
        .matches(validateNameRegex, 'Please enter a valid surname'),
    identityNumber: yup
        .string()
        .required('Please enter your ID card number')
        .when('$identityType', {
            is: val => {
                return val === 'Voters Card' ? true : false
            },
            then: yup
                .string()
                .required("Please enter your voter's card number")
                .matches(
                    VotersCardRegex,
                    'Voter’s Card Number should be 19 characters',
                ),
        })
        .when('$identityType', {
            is: val =>
                val === 'Virtual National Identification Number' ? true : false,
            then: yup
                .string()
                .required('Please enter your Virtual NIN')
                .min(
                    16,
                    'Your Virtual NIN should be 16 alphanumeric characters',
                )
                .max(
                    16,
                    'Your Virtual NIN should be 16 alphanumeric characters',
                ),
        })
        .when('$identityType', {
            is: val => (val === 'Drivers License' ? true : false),
            then: yup
                .string()
                .required("Please enter your driver's license number")
                .matches(
                    DriversLicenseRegex,
                    'Drivers License number is wrong',
                ),
        })
        .when('$identityType', {
            is: val =>
                val === 'National Identification Number' ? true : false,
            then: yup
                .string()
                .required('Please enter your NIN')
                .min(11, 'Your NIN should be 11 digits')
                .max(11, 'Your NIN should be 11 digits'),
        })
        .when('$identityType', {
            is: val => (val === 'International Passport' ? true : false),
            then: yup
                .string()
                .required('Please enter your international passport number')
                .matches(
                    InternationalPassportRegex,
                    'International Passport number is wrong',
                ),
        }),
})

export const validateRequestNewDealInfo = showUrgencyField =>
    yup.object().shape({
        urgency: showUrgencyField
            ? yup.string().required('Please select a level of urgency')
            : yup.string(),
        useType: yup
            .string()
            .required('Please select your use type to continue'),
    })

import PropTypes from 'prop-types'
import { useState, useEffect, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import SeoComponent from 'src/components/Seo'
import Toast from '../../Toast'
import Editor from '../../Editor'
import {
    NoFLoatingLabelInputFields,
    MultipleSelectField,
    SelectField,
    InputFields,
} from '../../InputFields'
import { numberWithCommas } from '../../../utils/formatting'
import Button from '../../Button'
import BackNav from '../../BackNav'
import GetModal from '../../Modal'
import AddMargin from '../AddMargin'
import { InlineLoader } from '../../Loader'
import CloseIcon from '../../../assets/images/close-icon.svg'
import DownArrow from '../../../assets/images/blackDownArrow.svg'
import UpArrow from '../../../assets/images/blackUpArrow.svg'
import QuestionMarkIcon from '../../../assets/images/questionMark.svg'
import styles from './editpackage.module.scss'
import FloatingBox from '../../FloatingBox'
import HelpCenter from '../../HelpCenter'
import { subStringText } from '../../../utils/formatting'
import { eventTrackers } from '../../../utils/eventTrackers.js'
import { appTracking } from '../../../utils/appTracker.js'
import { v4 as uuidv4 } from 'uuid'
import { formatPackageComponents, formatComponents } from './formatter'
import { updateComponentsListProcessing } from './util'

import {
    getSinglePackageApi,
    updateSinglePackageApi,
    getMaxWarranty,
} from '../../../api/products/packages'
import { getAllComponentsApi } from '../../../api/products/library'
import { currencyFormatter } from '../../../utils/currencyFormatter'
import AddComponent from '../../../pages/Products/AddComponent'

import useErrorHandler from '../../../utils/Hooks/useErrorHandler'
import { errorHandler } from '../../../utils/errorHandler'
import { denyPermission } from '../../../utils/permissionFramework'
import { checkLibraryProductAccess } from '../../../utils/checkLibraryProductAccess'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { decodeUserInfo } from 'src/utils/auth'
import { remoteMonitoringData } from '../GlobalPackage/data'
import { CombinedSelectAndInputField } from 'src/components/InputFields/CombinedSelectAndInputField'
import BlueCloseIcon from 'src/assets/images/blue-close-icon.svg'
import plusBlueIcon from 'src/assets/images/Plus-Blue.svg'
import {
    filteredProtectiveDevice,
    unitOfMeasurement,
    protectiveDeviceDescription,
} from '../AddProtectiveDevice/data'
import { PackageProtectiveDeviceValidationSchema } from 'src/utils/validationSchema'
import CostCard from '../CostCard'
import useMargin from '../hooks/useMargin'
import usePaymentPlanTypes from '../hooks/usePaymentPlanTypes'
import { isNumeric } from '../../../utils/formatting'
import EditWarrantyModal from '../AddComponents/components/EditWarrantyModal'
import {
    applyDynamicPermissionsToAdminUser,
    applyDynamicPermissionsToProviderUser,
    permissionsControl,
} from 'src/utils/permissionsControl'

const EditPackage = ({ match, productId, location }) => {
    const itemId = match?.params?.id || productId
    const {
        MainTitle,
        Wrapper,
        SubTitle,
        InputTitle,
        BtnWrapper,
        MobileLink,
        RightWrapper,
        ComponentTypeTableData,
        SelectedCapacityTableData,
        QuantityTableData,
        HandleCancelTableData,
    } = styles

    const [inputs, setInputs] = useState({
        packageName: '',
        packageDescription: '',
        isPackageGlobal: false,
        packageProviderId: '',
        packageWarranty: '',
        packagePaymentPlanTypes: [],
        remoteMonitoringCapabilities: '',
    })
    const [allComponents, setAllComponents] = useState([])
    const [components, setComponents] = useState([])
    const [errMessage, setErrMessage] = useState({
        packageName: '',
        packageDescription: '',
        components: '',
        packageWarranty: '',
        paymentPlanTypes: '',
        remoteMonitoringCapabilities: '',
    })
    const [selected, setSelected] = useState([])
    const [componentModal, setComponentModal] = useState(false)
    const [count, setCount] = useState([])
    const [total, setTotal] = useState(0)
    const [subtotal, setSubtotal] = useState(0)
    const [margin, setMargin] = useState({
        action: '',
        amount: '',
        percentage: '',
        isExisting: false,
    })
    const [modalOpen, setModalOpen] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [componentList, setComponentList] = useState([])
    const [packageComponents, setPackageComponents] = useState([])
    const [successMessage, setSuccessMessage] = useState('')
    const [errs, setErrs] = useState(null)
    const [errors, setErrors] = useState({})
    const [protectiveDevices, setProtectiveDevices] = useState([])
    const [selectedProtectiveDevices, setSelectedProtectiveDevices] = useState(
        [],
    )
    const [
        initialSelectedProtectiveDevices,
        setInitialSelectedProtectiveDevices,
    ] = useState([])
    const [selectedMargin, setSelectedMargin] = useState('') // financing || outright-sale
    const [maxWarranty, setMaxWarranty] = useState('')
    const [showEditWarrantyModal, setShowEditWarrantyModal] = useState(false)
    const [newPackageWarranty, setNewPackageWarranty] = useState('')
    const minWarranty = 3

    const pageTitle = 'SunFi - Edit A Package'
    const pageUrlName = window.location.pathname
    const pageTracker = 'EDIT_PACKAGE_TRACKER'
    const editorEditRef = useRef()
    const userInfo = decodeUserInfo()
    const paymentPlanTypesData = usePaymentPlanTypes()

    const editor = editorEditRef?.current?.getEditor()

    let history = useHistory()
    const {
        margin: outrightSaleMargin,
        total: totalForOutrightSale,
        subtotal: subtotalForOutrightSale,
        handleRemoveMargin: handleRemoveMarginForOutrightSale,
        getMargin: getMarginForOutrightSale,
        marginUnit: marginUnitForOutrightSale,
    } = useMargin(count)
    const { isMobile } = useMediaQueries()

    const isAdminWorkspace = history?.location?.state?.isAdminWorkspace ?? false
    const noEditButton = history?.location?.state?.noEdit
    const providerId =
        history?.location?.state?.providerId || history?.location?.state?.id
    const providerName =
        history?.location?.state?.providerName || history?.location?.state?.name
    const isSubscriptionProvider = userInfo?.isSubscriptionProvider === 'true'
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const unprivilegedEditor =
        editorEditRef?.current?.makeUnprivilegedEditor(editor)

    const editorValue = unprivilegedEditor?.getText().trim()
    const canCreateComponent = permissionsControl(['can_create_a_component'])

    const { isLoading: packageLoading, refetch: packageRefetch } = useQuery(
        ['fetchSinglePackage'],
        () => getSinglePackageApi(itemId, isAdminWorkspace),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const packageInfo = data?.data?.data

                const planTypes = []
                if (packageInfo?.subscription_supported) {
                    planTypes.push('Subscription')
                }

                if (packageInfo?.lease_to_own_supported) {
                    planTypes.push('Lease to Own')
                }

                if (packageInfo?.outright_sale_supported && isAdminUser) {
                    planTypes.push('Outright sale')
                }

                setProtectiveDevices(packageInfo?.protective_devices)
                setInitialSelectedProtectiveDevices(
                    packageInfo?.protective_devices,
                )

                setInputs(prev => ({
                    ...prev,
                    packageName: packageInfo?.name,
                    packageDescription: packageInfo?.description,
                    isPackageGlobal: packageInfo?.is_global,
                    packageProviderId: packageInfo?.provider_id,
                    packageWarranty: packageInfo?.warranty,
                    packagePaymentPlanTypes: planTypes,
                    remoteMonitoringCapabilities:
                        packageInfo?.remote_monitoring_capability,
                }))

                const packageMarginPercentage = parseFloat(packageInfo?.margin)
                if (packageMarginPercentage !== 0) {
                    const packageMarginAmount =
                        (packageMarginPercentage / 100) * packageInfo?.subtotal
                    const marginDescription =
                        packageMarginPercentage > 0
                            ? 'Add Margin'
                            : 'Subtract Margin'
                    handleMargin(
                        marginDescription,
                        packageMarginAmount < 0
                            ? packageMarginAmount * -1
                            : packageMarginAmount,
                        Math.abs(packageMarginPercentage),
                        true,
                        packageInfo?.subtotal,
                    )
                }

                const packageMarginPercentageForOutrightSale = parseFloat(
                    packageInfo?.outright_sale_margin,
                )
                if (packageMarginPercentageForOutrightSale !== 0) {
                    const packageMarginAmount =
                        (packageMarginPercentageForOutrightSale / 100) *
                        packageInfo?.subtotal
                    const marginDescription =
                        packageMarginPercentageForOutrightSale > 0
                            ? 'Add Margin'
                            : 'Subtract Margin'
                    getMarginForOutrightSale(
                        marginDescription,
                        packageMarginAmount < 0
                            ? packageMarginAmount * -1
                            : packageMarginAmount,
                        Math.abs(packageMarginPercentageForOutrightSale),
                        true,
                        packageInfo?.subtotal,
                    )
                }

                setPackageComponents(
                    formatPackageComponents(data?.data?.data?.components),
                )
            },
            onError: () => {
                setToastError(true)
                setErrorMessage(`Couldn't fetch package. Refresh page`)
            },
        },
    )

    useEffect(() => {
        const denyBtnAction =
            inputs?.isPackageGlobal &&
            !checkLibraryProductAccess(inputs?.packageProviderId)

        const denyEditPackageBtnAction = denyPermission(
            'admin',
            'product_library',
            'package:can_edit',
        )
        if (denyBtnAction || denyEditPackageBtnAction) {
            history.push('/app/403-page')
        }
    }, [history, inputs?.isPackageGlobal, inputs?.packageProviderId])

    const { isLoading: fetchComponentsLoading, refetch: fetchComponents } =
        useQuery(
            ['fetchAllComponents'],
            () => getAllComponentsApi(isAdminWorkspace),
            {
                enabled: false,
                refetchIntervalInBackground: true,
                retry: false,
                onSuccess: data => {
                    setComponentList(data?.data?.data)
                },
                onError: () => {
                    setToastError(true)
                    setErrorMessage('Failed to fetch components. Refresh page')
                },
            },
        )

    const updateProtectiveDevices = () => {
        const removedDevices = protectiveDevices
            .filter(
                device => !initialSelectedProtectiveDevices.includes(device),
            )
            .map(device => device.id)
        return { removedDevices }
    }
    const { removedDevices } = updateProtectiveDevices()
    const {
        isFetching: updatePackageFetching,
        refetch: updatePackageRefetch,
        error: updatePackageErr,
        data: updatePackageData,
    } = useQuery(
        ['updatePackage'],
        () =>
            updateSinglePackageApi(
                itemId,
                {
                    name: inputs.packageName,
                    margin:
                        margin.action === 'Add Margin'
                            ? margin.percentage
                            : -margin.percentage,
                    ...(isAdminUser && {
                        outright_sale_margin: parseFloat(
                            marginUnitForOutrightSale || 0,
                        ),
                    }),
                    description: inputs.packageDescription,
                    warranty: inputs.packageWarranty,
                    subscription_enabled: inputs.packagePaymentPlanTypes.find(
                        record => record === 'Subscription',
                    )
                        ? true
                        : false,
                    l2O_enabled: inputs.packagePaymentPlanTypes.find(
                        record => record === 'Lease to Own',
                    )
                        ? true
                        : false,
                    ...(isAdminUser && {
                        outright_sale_supported:
                            inputs.packagePaymentPlanTypes.find(
                                record => record === 'Outright sale',
                            )
                                ? true
                                : false,
                    }),
                    components: updateComponentsListProcessing(
                        formatComponents(selected),
                        packageComponents,
                    ),
                    ...(process.env.REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE ===
                        'true' &&
                        selectedProtectiveDevices.length > 0 && {
                            create_protective_devices:
                                selectedProtectiveDevices.map(device => {
                                    return ['Overall Protection'].includes(
                                        device.category,
                                    )
                                        ? {
                                              name: device.name,
                                              category: device.category,
                                              quantity: device.quantity,
                                          }
                                        : {
                                              name: device.name,
                                              category: device.category,
                                              unit_of_measurement:
                                                  device.unit_of_measurement ||
                                                  'Amps (A)',
                                              rating: device.rating,
                                              quantity: device.quantity,
                                          }
                                }),
                        }),
                    ...(process.env.REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE ===
                        'true' && {
                        remove_protective_device_ids: removedDevices,
                    }),
                    remote_monitoring_capability:
                        inputs.remoteMonitoringCapabilities,
                },
                isAdminWorkspace,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setSuccessMessage('Your changes have been saved')
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['editPackage'].action,
                    eventTrackers['editPackage'].label,
                    eventTrackers['editPackage'].category,
                )
                setSelectedProtectiveDevices([])
                setErrorMessage('')
                setErrors({})
                setErrMessage({
                    packageName: '',
                    packageDescription: '',
                    components: '',
                    packageWarranty: '',
                    paymentPlanTypes: '',
                    remoteMonitoringCapabilities: '',
                })
                setErrs(null)
                packageRefetch()
            },
            onError: data => {
                let errors = data?.response?.data?.errors
                if (errors?.[itemId]) {
                    setErrorMessage(errorHandler(errors?.[itemId]))
                } else if (errors) {
                    if (errors?.name) {
                        setErrs(data?.response?.data)
                    } else if (errors?.margin) {
                        setErrorMessage(
                            errorHandler(data?.response?.data?.errors),
                        )
                    } else if (
                        Object.keys(errors?.components?.add).length !== 0
                    ) {
                        setErrorMessage(errorHandler(errors?.components?.add))
                    } else if (
                        Object.keys(errors?.components?.remove).length !== 0
                    ) {
                        setErrorMessage(
                            errorHandler(errors?.components?.remove),
                        )
                    } else if (
                        Object.keys(errors?.components?.update).length !== 0
                    ) {
                        setErrorMessage(
                            errorHandler(errors?.components?.update),
                        )
                    }

                    // setErrorMessage(errorHandler(data?.response?.data))
                } else {
                    setErrorMessage(['Failed to update package. Try again'])
                }
                setToastError(true)
            },
        },
    )
    const handleInputChange = e => {
        setErrMessage({ packageName: '' })
        const { name, value } = e.target
        setInputs(prev => ({ ...prev, [name]: value }))
    }

    const { refetch: warrantyRefetch } = useQuery(
        ['getmaxwarranty'],
        () => getMaxWarranty(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setMaxWarranty(data?.data?.data?.max_warranty)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const handleWarrantyChange = e => {
        setErrMessage({ packageWarranty: '' })
        const { name, value } = e.target
        if (value.trim() === '') {
            setInputs(prev => ({ ...prev, [name]: '' }))
            setErrMessage({ packageWarranty: 'This field is required' })
            return
        }
        if (!isNumeric(value)) {
            value.replaceAll(/[a-zA-Z]/g, '')
            return
        }
        setInputs(prev => ({ ...prev, [name]: value }))
        if (
            parseFloat(value) < minWarranty ||
            (maxWarranty !== '' && parseFloat(value) > maxWarranty)
        ) {
            setErrMessage({
                packageWarranty:
                    maxWarranty === ''
                        ? 'Warranty should not be less than 12 months'
                        : `Your warranty period must be between 12 and ${maxWarranty} months`,
            })
        }
    }

    const handleBlur = e => {
        const { name, value } = e.target
        setInputs(prev => ({ ...prev, [name]: value.trim() }))

        if (name === 'packageWarranty') {
            handleWarrantiesCheck()
        }
    }
    //stored newly created components
    const [componentData, setComponentData] = useState()
    //pass a call back to children components
    const [componentCallback, setcomponentCallback] = useState(0)
    const callback = useCallback(componentCallback => {
        setcomponentCallback(componentCallback)
    }, [])

    useEffect(() => {
        let newComponent = JSON.parse(localStorage.getItem('ComponentData'))
        newComponent !== null && setComponentData(newComponent)
        setComponentModal(false)
    }, [componentCallback])
    const handleSelectChange = useCallback(
        value => {
            setComponents([...components, value])
            let filteredComponent = allComponents.find(item => {
                const formattedItem = `${item?.name} (₦${currencyFormatter(
                    item?.cost,
                )} | ${item?.type?.name})`
                if (formattedItem == value) return item
            })
            let preselectedfilterComponent = [...selected, filteredComponent]
            const updatedSelected = preselectedfilterComponent.map(
                component => {
                    return {
                        ...component,
                        estimatedValue: `${
                            component?.name
                        } (₦${numberWithCommas(component?.cost)} | ${
                            component?.type
                        })`,
                        count: component?.count || 1,
                        realCost:
                            component?.realCost === undefined
                                ? component?.cost
                                : component?.realCost,
                    }
                },
            )
            const countList = updatedSelected.map(item => ({
                id: item.id,
                cost: item.cost,
                count: item?.count,
                realCost: item?.realCost,
            }))

            setCount(countList)
            setSelected(updatedSelected)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allComponents, selected],
    )

    const getCount = componentId => {
        return count.filter(value => value.id === componentId)[0].count
    }

    const updateCount = (currentCount, action, componentId, cost, realCost) => {
        let newCount = {
            id: componentId,
            cost: cost,
            count: currentCount,
            realCost,
        }
        if (action === 'add') {
            newCount.count = currentCount + 1
            newCount.cost = realCost * (currentCount + 1)
        }
        if (action === 'subtract' && currentCount > 1) {
            newCount.count = currentCount - 1
            newCount.cost = realCost * (currentCount - 1)
        }

        let countCopy = [...count]
        let selectedCopy = [...selected]

        let found = countCopy.findIndex(q => q.id === componentId)
        let foundSelected = selectedCopy.findIndex(q => q.id === componentId)

        if (found > -1) {
            countCopy[found] = newCount
        }

        if (foundSelected > -1) {
            selectedCopy[foundSelected] = {
                ...selectedCopy[foundSelected],
                cost: newCount.cost,
                count: newCount.count,
            }
        }

        setCount(countCopy)
        setSelected(selectedCopy)
    }

    const handleCancel = id => {
        let newComponent = selected.filter(data => data.id !== id)
        const checked = newComponent.map(data => data.value)

        let countList = []

        newComponent.forEach(item => {
            countList.push({
                id: item?.id,
                cost: item?.cost,
                count: item?.count,
                realCost: item?.realCost,
            })
        })

        setCount(countList)
        setComponents(checked)
        setSelected(newComponent)
    }

    const handleMargin = (action, amount, percentage, isExisting) => {
        const formatAmount = amount.toString().replaceAll(',', '')
        setMargin({ action, amount: formatAmount, percentage, isExisting })

        if (action === 'Add Margin') {
            let Total = subtotal + parseFloat(formatAmount)
            setTotal(Total)
        } else if (action === 'Subtract Margin') {
            let Total = subtotal - parseFloat(formatAmount)
            setTotal(Total)
        } else {
            setTotal(subtotal)
        }
    }

    const closeEditWarrantyModal = () => {
        setShowEditWarrantyModal(false)
    }

    const changePackageWarranty = () => {
        setInputs(prev => ({
            ...prev,
            packageWarranty: newPackageWarranty,
        }))
        setShowEditWarrantyModal(false)
    }

    const handleWarrantiesCheck = () => {
        if (
            !(
                isAdminWorkspace &&
                inputs?.packageWarranty !== '' &&
                selected?.length > 0
            )
        ) {
            return true
        }

        const allWarrantiesMatch = selected.every(component => {
            const isAnInverter =
                component?.type === 'Inverter' ||
                component?.type?.name === 'Inverter'
            const warrantyMatches =
                parseInt(component?.meta_data?.warranty) ===
                parseInt(inputs?.packageWarranty)

            return (
                !isAnInverter ||
                !component?.meta_data?.warranty ||
                warrantyMatches
            )
        })

        if (!allWarrantiesMatch) {
            const nonMatchingInverter = selected.find(component => {
                const isAnInverter =
                    component?.type === 'Inverter' ||
                    component?.type?.name === 'Inverter'
                return (
                    isAnInverter &&
                    component?.meta_data?.warranty &&
                    parseInt(component?.meta_data?.warranty) !==
                        parseInt(inputs?.packageWarranty)
                )
            })

            if (nonMatchingInverter) {
                setNewPackageWarranty(nonMatchingInverter?.meta_data?.warranty)
                setShowEditWarrantyModal(true)
            }
        }

        return allWarrantiesMatch
    }

    const handleSelectComponentBlur = () => {
        handleWarrantiesCheck()
    }

    useEffect(() => {
        const allComponentsList = componentList
        const formattedComponent = [componentData][0]?.name
            ? [componentData].map(component => ({
                  name: component?.name,
                  count: 1,
                  cost: parseFloat(component?.cost),
                  id: component?.id,
                  type: component?.type.name,
                  realCost: component?.cost,
              }))
            : ''
        const packageComponentsList = [
            ...formattedComponent,
            ...packageComponents,
        ]

        let formatAllComponentList = allComponentsList?.map(item => {
            return {
                ...item,
                value: `${item?.name} (₦${currencyFormatter(item?.cost)} | ${
                    item?.type?.name
                })`,
            }
        })

        let formatPackageComponentList = packageComponentsList?.map(item => {
            return {
                ...item,
                value: `${item?.name} (₦${currencyFormatter(
                    item?.realCost,
                )} | ${item?.type})`,
                estimatedValue: `${item?.name} (₦${currencyFormatter(
                    item?.cost,
                )} | ${item?.type})`,
            }
        })

        let countList = []
        let checked = []

        formatPackageComponentList.forEach(item => {
            checked.push(item.value)
            countList.push({
                id: item.id,
                cost: item.cost,
                count: item?.count,
                realCost: parseFloat(item?.realCost, 10),
            })
        })

        setCount(countList)
        setComponents(checked)
        setAllComponents(formatAllComponentList)
        setSelected(formatPackageComponentList)

        // eslint-disable-next-line
    }, [packageComponents, componentList, componentData])

    useEffect(() => {
        fetchComponents()
        !isAdminWorkspace && warrantyRefetch()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let calculateSubtotal = count.reduce(
            (total, item) => total + item?.realCost * item?.count,
            0,
        )
        setSubtotal(calculateSubtotal)

        if (margin?.action === 'Add Margin') {
            setTotal(calculateSubtotal + margin.amount)
        } else if (margin?.action === 'Subtract Margin') {
            setTotal(calculateSubtotal - margin.amount)
        }
        const packageMarginPercentage = margin
            ? parseFloat(margin.percentage)
            : 0
        if (
            packageMarginPercentage !== 0 &&
            calculateSubtotal !== 0 &&
            subtotal !== 0
        ) {
            const packageMarginAmount =
                (packageMarginPercentage / 100) * calculateSubtotal
            const marginDescription =
                margin.action === 'Add Margin'
                    ? 'Add Margin'
                    : 'Subtract Margin'
            handleMargin(
                marginDescription,
                packageMarginAmount || '',
                packageMarginPercentage || '',
                margin.amount === '' ? false : true,
                calculateSubtotal,
            )
        }
        // eslint-disable-next-line
    }, [count, selected, subtotal])

    const handleUpdatePackage = () => {
        if (isAdminWorkspace) {
            const allWarrantiesMatch = handleWarrantiesCheck()
            if (!allWarrantiesMatch) return
        }

        if (inputs.packageName === '') {
            setErrMessage({ packageName: 'This Field is Required' })
        } else if (!editorValue) {
            setErrMessage({ packageDescription: 'This Field is Required' })
        } else if (inputs.packageWarranty === '') {
            setErrMessage({ packageWarranty: 'This field is required' })
        } else if (
            parseInt(inputs.packageWarranty) < minWarranty ||
            (maxWarranty !== '' &&
                parseInt(inputs.packageWarranty) > maxWarranty)
        ) {
            setErrMessage({
                packageWarranty: `${
                    maxWarranty === ''
                        ? 'Warranty should not be less than 12 months'
                        : `Your warranty period must be between 12 and ${maxWarranty} months`
                }`,
            })
        } else if (
            (isAdminWorkspace || isSubscriptionProvider) &&
            inputs.packagePaymentPlanTypes.length === 0
        ) {
            setErrMessage({
                packagePaymentPlanTypes:
                    'Supported payment plan types should not be empty',
            })
        } else if (components?.length < 1) {
            setErrMessage({ components: 'This Field is Required' })
        } else if (inputs.remoteMonitoringCapabilities === '') {
            setErrMessage({
                remoteMonitoringCapabilities: 'This field is required',
            })
        } else if (
            process.env.REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE === 'true' &&
            selectedProtectiveDevices.length > 0
        ) {
            PackageProtectiveDeviceValidationSchema.validate(
                {
                    inputs: [
                        ...initialSelectedProtectiveDevices.map(prev => {
                            return {
                                ...prev,
                                unit_of_measurement:
                                    prev.unit_of_measurement ?? 'NA',
                            }
                        }),
                        ...selectedProtectiveDevices,
                    ],
                },
                {
                    abortEarly: false,
                },
            )
                .then(() => {
                    updatePackageRefetch()
                })
                .catch(err => {
                    let errList = {}
                    err.inner.forEach(e => {
                        errList = {
                            ...errList,
                            [e.path]: e.message,
                        }
                    })

                    setErrors(errList)
                })
        } else {
            updatePackageRefetch()
        }
    }

    const handleEditorChange = value => {
        // setErrMessage({ packageDescription: '' })
        setInputs(prev => ({
            ...prev,
            packageDescription: value,
        }))
    }

    const handleRemoveMargin = () => {
        setMargin({
            action: '',
            amount: '',
            percentage: '',
            isExisting: false,
        })
        setTotal(subtotal)
    }

    useEffect(() => {
        packageRefetch()
        setErrs(null)
        // eslint-disable-next-line
    }, [itemId])

    const errorValue = useErrorHandler(errs)

    const backToPackages = url => {
        history.push(
            providerId?.length < 0
                ? url
                : {
                      pathname: url,
                      search: '?tab=packages',
                      state: {
                          id: providerId,
                          name: providerName,
                          isAdminWorkspace: isAdminWorkspace,
                          sunfiId: noEditButton ? '' : providerId,
                      },
                  },
        )
    }

    const truncateRemoteMonitoringCapabilities =
        inputs.remoteMonitoringCapabilities &&
        inputs.remoteMonitoringCapabilities.length > 40
            ? inputs.remoteMonitoringCapabilities.substring(0, 40) + '...'
            : inputs.remoteMonitoringCapabilities

    // protective device functionality
    const addPDRow = () => {
        setSelectedProtectiveDevices(prev => [
            ...prev,
            {
                id: uuidv4(),
                name: '',
                category: '',
                rating: '',
                quantity: '',
                unit_of_measurement: 'Amps (A)',
                disabled: false,
            },
        ])
    }

    const removeRow = id => {
        const filteredDevice = selectedProtectiveDevices.filter(
            device => device.id !== id,
        )
        const filteredInitialDevice = initialSelectedProtectiveDevices.filter(
            device => device.id !== id,
        )
        setSelectedProtectiveDevices(filteredDevice)
        setInitialSelectedProtectiveDevices(filteredInitialDevice)
    }

    const handleAddOrEditMargin = selected => {
        setSelectedMargin(selected)
        setModalOpen(true)
    }

    const closeAddMarginModal = () => {
        setSelectedMargin('')
        setModalOpen(false)
    }

    return (
        <>
            <EditWarrantyModal
                showModal={showEditWarrantyModal}
                changePackageWarranty={changePackageWarranty}
                handleGoBack={closeEditWarrantyModal}
            />
            {/* page */}
            <SeoComponent
                title="Edit Package - Products | SunFi | Simplifying and Scaling Clean Energy"
                tracker="Products"
            />
            <div className={MobileLink}>
                <BackNav
                    title={
                        location?.state?.previousPath === 'viewPackage'
                            ? 'Back to Package'
                            : 'Back to Packages'
                    }
                    onClick={
                        location?.state?.previousPath === 'viewPackage'
                            ? history.goBack
                            : () => backToPackages('/app/products')
                    }
                />
            </div>
            {!packageLoading ? (
                <>
                    {toastError || updatePackageErr ? (
                        <Toast
                            messageType="error"
                            message={
                                updatePackageFetching ||
                                packageLoading ||
                                fetchComponentsLoading
                                    ? ''
                                    : errorMessage[0]?.id ||
                                      errorMessage[0] ||
                                      errorValue['name']?.[0]
                            }
                        />
                    ) : updatePackageData ? (
                        <Toast messageType="success" message={successMessage} />
                    ) : (
                        ''
                    )}
                    <div className={Wrapper}>
                        <div className={''}>
                            <h1 className={MainTitle}>Edit Products</h1>
                            <h2 className={SubTitle}>Basic Information</h2>
                            <p className={InputTitle}>Package Name</p>
                            <NoFLoatingLabelInputFields
                                type="text"
                                inputWidth="390px"
                                handleChange={handleInputChange}
                                value={inputs.packageName}
                                name="packageName"
                                errorMessage={errMessage.packageName}
                                TextPrefilled={
                                    inputs.packageName?.length > 0
                                        ? true
                                        : false
                                }
                                onBlur={handleBlur}
                            />
                            <div style={{ marginTop: '40px' }}>
                                <p className={InputTitle}>
                                    Package Description
                                </p>
                                <Editor
                                    placeholder="Package Description"
                                    editorWidth={isMobile ? '100%' : '390px'}
                                    editorHeight="140px"
                                    value={inputs.packageDescription}
                                    onChangeHandler={handleEditorChange}
                                    errorMessage={
                                        errMessage?.packageDescription
                                    }
                                    editorRef={editorEditRef}
                                    TextPrefilled={editorValue ? true : false}
                                />
                            </div>
                            <div style={{ marginTop: '40px' }}>
                                <p className={InputTitle}>Package Warranty</p>
                                <NoFLoatingLabelInputFields
                                    type="text"
                                    inputWidth="390px"
                                    handleChange={handleWarrantyChange}
                                    value={inputs.packageWarranty}
                                    name="packageWarranty"
                                    errorMessage={errMessage.packageWarranty}
                                    TextPrefilled={
                                        inputs.packageWarranty?.length > 0
                                            ? true
                                            : false
                                    }
                                    onBlur={handleBlur}
                                    blurOnMouseOut={false}
                                />
                            </div>
                            <div style={{ marginTop: '40px' }}>
                                {(isAdminWorkspace ||
                                    isSubscriptionProvider) && (
                                    <MultipleSelectField
                                        title="Supported Payment Plan Types"
                                        floatingLabel="Supported Payment Plan Types"
                                        selectWidth="100%"
                                        name="payment_plan_models"
                                        values={paymentPlanTypesData}
                                        value={inputs.packagePaymentPlanTypes}
                                        inputValue={
                                            inputs.packagePaymentPlanTypes
                                        }
                                        dropDownWidth="150px"
                                        dropdownMatchSelectWidth={false}
                                        handleMultipleSelectChange={value => {
                                            setInputs(prev => ({
                                                ...prev,
                                                ['packagePaymentPlanTypes']:
                                                    value,
                                            }))
                                            if (
                                                !value.includes('Outright sale')
                                            ) {
                                                handleRemoveMarginForOutrightSale()
                                            }
                                            if (
                                                !value.includes(
                                                    'Lease to Own',
                                                ) &&
                                                !value.includes('Subscription')
                                            ) {
                                                handleRemoveMargin()
                                            }
                                        }}
                                        errorMessage={
                                            errMessage.paymentPlanTypes
                                        }
                                    />
                                )}
                            </div>

                            <SelectField
                                selectWidth="100%"
                                initialOption={
                                    inputs.remoteMonitoringCapabilities ||
                                    'Select Remote Monitoring Capabilities'
                                }
                                name="remoteMonitoringCapabilities"
                                withCheckBox={true}
                                currentSelected={
                                    inputs.remoteMonitoringCapabilities
                                }
                                handleChange={(name, value) =>
                                    setInputs(prev => ({
                                        ...prev,
                                        ['remoteMonitoringCapabilities']: value,
                                    }))
                                }
                                errorMessage={
                                    errMessage?.remoteMonitoringCapabilities
                                }
                                value={truncateRemoteMonitoringCapabilities}
                                values={remoteMonitoringData.map(option => ({
                                    value: option,
                                }))}
                                dropdownPositionRelative
                                showSearch
                                marginBottom={20}
                            />
                            {process.env
                                .REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE ===
                                'true' &&
                                (initialSelectedProtectiveDevices.length > 0 ||
                                    selectedProtectiveDevices.length > 0) && (
                                    <div>
                                        <div className="PDParentWrapper">
                                            {[
                                                ...initialSelectedProtectiveDevices,
                                                ...selectedProtectiveDevices,
                                            ].map((item, i) => (
                                                <div
                                                    className="PDWrapper"
                                                    key={item.id}
                                                >
                                                    <div>
                                                        <SelectField
                                                            initialOption={
                                                                'Add Description'
                                                            }
                                                            floatingLabel={
                                                                'Add Description'
                                                            }
                                                            floatLabelOnSelectedValue
                                                            selectWidth="224px"
                                                            values={protectiveDeviceDescription?.map(
                                                                option => ({
                                                                    value: option,
                                                                }),
                                                            )}
                                                            name="description"
                                                            showSearch
                                                            withCheckBox={true}
                                                            handleChange={(
                                                                name,
                                                                value,
                                                            ) => {
                                                                item.category =
                                                                    value
                                                                item.name = ''
                                                                item.rating = [
                                                                    'Overall Protection',
                                                                ].includes(
                                                                    item.category,
                                                                )
                                                                    ? 'NA'
                                                                    : ''
                                                                setSelectedProtectiveDevices(
                                                                    [
                                                                        ...selectedProtectiveDevices,
                                                                    ],
                                                                )
                                                            }}
                                                            disabled={
                                                                item.disabled ===
                                                                false
                                                                    ? false
                                                                    : true
                                                            }
                                                            errorMessage={
                                                                errors[
                                                                    `inputs[${i}].category`
                                                                ]
                                                            }
                                                            dropdownPositionRelative
                                                            currentSelected={
                                                                item.category
                                                            }
                                                            value={
                                                                item.category
                                                            }
                                                            marginBottom="10px"
                                                        />
                                                        <SelectField
                                                            initialOption={
                                                                'Add protective device'
                                                            }
                                                            floatingLabel={
                                                                'Add protective device'
                                                            }
                                                            floatLabelOnSelectedValue
                                                            selectWidth="224px"
                                                            values={filteredProtectiveDevice(
                                                                item.category,
                                                            )?.map(option => ({
                                                                value: option,
                                                            }))}
                                                            disabled={
                                                                item.disabled ===
                                                                    false ||
                                                                item.category
                                                                    ?.length < 1
                                                                    ? false
                                                                    : true
                                                            }
                                                            name="protective_device"
                                                            showSearch
                                                            withCheckBox={true}
                                                            handleChange={(
                                                                name,
                                                                value,
                                                            ) => {
                                                                item.name =
                                                                    value
                                                                item.rating = [
                                                                    'Overall Protection',
                                                                ].includes(
                                                                    item.category,
                                                                )
                                                                    ? 'NA'
                                                                    : ''
                                                                setSelectedProtectiveDevices(
                                                                    [
                                                                        ...selectedProtectiveDevices,
                                                                    ],
                                                                )
                                                            }}
                                                            dropdownPositionRelative
                                                            errorMessage={
                                                                errors[
                                                                    `inputs[${i}].name`
                                                                ]
                                                            }
                                                            currentSelected={
                                                                item.name
                                                            }
                                                            value={item.name}
                                                            marginBottom="10px"
                                                        />
                                                    </div>
                                                    <div>
                                                        {![
                                                            'Overall Protection',
                                                        ].includes(
                                                            item.category,
                                                        ) && (
                                                            <CombinedSelectAndInputField
                                                                title={
                                                                    'Enter Rating'
                                                                }
                                                                selectedValue={
                                                                    item.unit_of_measurement
                                                                }
                                                                values={unitOfMeasurement.map(
                                                                    val => {
                                                                        return {
                                                                            label: val,
                                                                            value: val,
                                                                        }
                                                                    },
                                                                )}
                                                                name="rating"
                                                                inputType="number"
                                                                dropDown
                                                                inputWidth={
                                                                    '224px'
                                                                }
                                                                dropdownPositionRelative
                                                                withCheckBox={
                                                                    true
                                                                }
                                                                handleSelect={data => {
                                                                    item.unit_of_measurement =
                                                                        data
                                                                    setSelectedProtectiveDevices(
                                                                        [
                                                                            ...selectedProtectiveDevices,
                                                                        ],
                                                                    )
                                                                }}
                                                                disabled={
                                                                    item.disabled ===
                                                                    false
                                                                        ? false
                                                                        : true
                                                                }
                                                                handleChange={e => {
                                                                    item.rating =
                                                                        e.target.value
                                                                    setSelectedProtectiveDevices(
                                                                        [
                                                                            ...selectedProtectiveDevices,
                                                                        ],
                                                                    )
                                                                }}
                                                                errorMessage={
                                                                    errors[
                                                                        `inputs[${i}].unit_of_measurement`
                                                                    ] ||
                                                                    errors[
                                                                        `inputs[${i}].rating`
                                                                    ]
                                                                }
                                                                inputValue={
                                                                    item.rating
                                                                }
                                                                marginBottom="10px"
                                                            />
                                                        )}
                                                        <InputFields
                                                            title={
                                                                'Add quantity'
                                                            }
                                                            inputWidth="224px"
                                                            type="number"
                                                            name="quantity"
                                                            handleChange={e => {
                                                                item.quantity =
                                                                    e.target.value
                                                                setSelectedProtectiveDevices(
                                                                    [
                                                                        ...selectedProtectiveDevices,
                                                                    ],
                                                                )
                                                            }}
                                                            disabled={
                                                                item.disabled ===
                                                                false
                                                                    ? false
                                                                    : true
                                                            }
                                                            errorMessage={
                                                                errors[
                                                                    `inputs[${i}].quantity`
                                                                ]
                                                            }
                                                            value={
                                                                item.quantity
                                                            }
                                                            marginBottom="10px"
                                                        />
                                                    </div>

                                                    {[
                                                        ...initialSelectedProtectiveDevices,
                                                        ...selectedProtectiveDevices,
                                                    ].length > 1 && (
                                                        <div
                                                            className="PDRemoveDeviceWrapper"
                                                            onClick={() => {
                                                                removeRow(
                                                                    item?.id,
                                                                )
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    BlueCloseIcon
                                                                }
                                                                alt="close icon"
                                                            />
                                                            <p>Remove device</p>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                        {/* Add new row */}
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            }}
                                            className="AddBtn"
                                        >
                                            <Button
                                                btnBgColor="var(--purple-light)"
                                                btnTextColor="var(--blue)"
                                                btnTextColorOutline="var(--blue)"
                                                btnOutlineColor="var(--purple-light)"
                                                btnBgColorOutline="#E2EEFF"
                                                type="outline"
                                                btnWidth="141px"
                                                handleClick={addPDRow}
                                            >
                                                <img
                                                    src={plusBlueIcon}
                                                    alt="plus-icon"
                                                    style={{
                                                        marginRight: '12.75px',
                                                    }}
                                                />
                                                Add New
                                            </Button>
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div className={RightWrapper}>
                            <h2 className={SubTitle}>Components Information</h2>
                            <p className={InputTitle}>Select Components</p>
                            <MultipleSelectField
                                values={allComponents}
                                value={selected.map(item => item.value)}
                                onSelect
                                handleMultipleSelectChange2={handleSelectChange}
                                selectWidth="99%"
                                dropDownWidth="390px"
                                title="Select Component"
                                inputValue={components}
                                errorMessage={errMessage.components}
                                maxTagCount="responsive"
                                maxTagPlaceholder="Select Component"
                                mobileWidth
                                disabled={fetchComponentsLoading ? true : false}
                                onBlur={handleSelectComponentBlur}
                            />
                            {applyDynamicPermissionsToProviderUser ||
                            applyDynamicPermissionsToAdminUser
                                ? canCreateComponent
                                : !denyPermission(
                                      'admin',
                                      'product_library',
                                      'component:can_create',
                                  ) && (
                                      <p
                                          className="ComponentLink"
                                          onClick={() =>
                                              setComponentModal(true)
                                          }
                                      >
                                          Create New Component
                                      </p>
                                  )}

                            <div className="SelectedComponentsWrapper">
                                <table>
                                    {selected.length > 0 &&
                                        selected.map((item, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    className="StepOneSelectedComponents"
                                                >
                                                    <td
                                                        className="ERComponentsFirstTD"
                                                        style={{
                                                            width: isMobile
                                                                ? '88%'
                                                                : '50%',
                                                        }}
                                                    >
                                                        <div className="ERComponentsFirstTDDiv">
                                                            <span className="ERComponentsFirstTDSelectedName">
                                                                {subStringText(
                                                                    item?.name,
                                                                    30,
                                                                )}{' '}
                                                            </span>
                                                        </div>
                                                        <span className="ERComponentAmountMobile">
                                                            NGN{' '}
                                                            {currencyFormatter(
                                                                item?.realCost,
                                                            )}
                                                        </span>
                                                    </td>
                                                    {!isMobile && (
                                                        <td
                                                            className={
                                                                ComponentTypeTableData
                                                            }
                                                        >
                                                            <span className="">
                                                                {subStringText(
                                                                    item?.type
                                                                        ?.name ||
                                                                        item?.type,
                                                                    9,
                                                                )}
                                                            </span>
                                                        </td>
                                                    )}
                                                    {!isMobile && (
                                                        <td
                                                            className={
                                                                SelectedCapacityTableData
                                                            }
                                                            style={{
                                                                width: '25%',
                                                            }}
                                                        >
                                                            <span className="SelectedCapacity">
                                                                &#8358;
                                                                {currencyFormatter(
                                                                    item?.realCost,
                                                                )}
                                                            </span>
                                                        </td>
                                                    )}
                                                    <td
                                                        className={
                                                            QuantityTableData
                                                        }
                                                        style={{
                                                            width: '7%',
                                                        }}
                                                    >
                                                        <div className="Scroll">
                                                            <span
                                                                className="QuantityImageWrapper"
                                                                onClick={() =>
                                                                    updateCount(
                                                                        getCount(
                                                                            item.id,
                                                                        ),
                                                                        'add',
                                                                        item?.id,
                                                                        item?.cost,
                                                                        item?.realCost,
                                                                    )
                                                                }
                                                                disabled={
                                                                    count >= 10
                                                                        ? true
                                                                        : false
                                                                }
                                                                style={{
                                                                    cursor:
                                                                        count >=
                                                                            10 &&
                                                                        'not-allowed',
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        UpArrow
                                                                    }
                                                                    className="Arrow"
                                                                    alt="up-arrow-icon"
                                                                />
                                                            </span>

                                                            <span className="Quantity">
                                                                {getCount(
                                                                    item.id,
                                                                )}
                                                            </span>
                                                            <span
                                                                className="QuantityImageWrapper"
                                                                onClick={() =>
                                                                    updateCount(
                                                                        getCount(
                                                                            item.id,
                                                                        ),
                                                                        'subtract',
                                                                        item?.id,
                                                                        item?.cost,
                                                                        item?.realCost,
                                                                    )
                                                                }
                                                                disabled={
                                                                    count <= 1
                                                                        ? true
                                                                        : false
                                                                }
                                                                style={{
                                                                    cursor:
                                                                        count <=
                                                                            1 &&
                                                                        'not-allowed',
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        DownArrow
                                                                    }
                                                                    className="Arrow"
                                                                    alt="down-arrow-icon"
                                                                />
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className={
                                                            HandleCancelTableData
                                                        }
                                                    >
                                                        <span
                                                            onClick={() =>
                                                                handleCancel(
                                                                    item.id,
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={CloseIcon}
                                                                alt="close-icon"
                                                            />
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </table>
                            </div>

                            <>
                                {selected?.length > 0 &&
                                    (inputs.packagePaymentPlanTypes?.includes(
                                        'Lease to Own',
                                    ) ||
                                        inputs.packagePaymentPlanTypes?.includes(
                                            'Subscription',
                                        )) && (
                                        <CostCard
                                            title="FINANCING"
                                            margin={margin}
                                            total={total}
                                            costOfComponents={subtotal}
                                            selected={selected}
                                            handleMargin={() =>
                                                handleAddOrEditMargin(
                                                    'financing',
                                                )
                                            }
                                            handleRemoveMargin={
                                                handleRemoveMargin
                                            }
                                            styleMargin={'22px 0 0 0'}
                                        />
                                    )}
                                {selected?.length > 0 &&
                                    inputs.packagePaymentPlanTypes?.includes(
                                        'Outright sale',
                                    ) && (
                                        <CostCard
                                            title="OUTRIGHT SALE"
                                            margin={outrightSaleMargin}
                                            total={totalForOutrightSale}
                                            costOfComponents={
                                                subtotalForOutrightSale
                                            }
                                            selected={selected}
                                            handleMargin={() =>
                                                handleAddOrEditMargin(
                                                    'outright-sale',
                                                )
                                            }
                                            handleRemoveMargin={
                                                handleRemoveMarginForOutrightSale
                                            }
                                            styleMargin={'14px 0 0 0'}
                                        />
                                    )}
                            </>
                        </div>
                    </div>
                    <div className={BtnWrapper}>
                        <Button
                            btnBgColor="var(--blue)"
                            btnTextColor="var(--white)"
                            btnTextColorOutline="var(--blue)"
                            btnOutlineColor="var(--purple-light)"
                            btnBgColorOutline="#E2EEFF"
                            btnWidth="160px"
                            btnHeight="56px"
                            handleClick={handleUpdatePackage}
                            disabled={updatePackageFetching ? true : false}
                        >
                            {updatePackageFetching ? (
                                <InlineLoader />
                            ) : (
                                'Save changes'
                            )}
                        </Button>
                    </div>
                    <GetModal
                        app="workspace"
                        showModal={modalOpen}
                        onCancel={closeAddMarginModal}
                        closable={true}
                        content={
                            <AddMargin
                                packageName={inputs.packageName}
                                subTotal={
                                    selectedMargin === 'financing'
                                        ? subtotal
                                        : subtotalForOutrightSale
                                }
                                margin={
                                    selectedMargin === 'financing'
                                        ? margin
                                        : outrightSaleMargin
                                }
                                handleClose={closeAddMarginModal}
                                handleMargin={
                                    selectedMargin === 'financing'
                                        ? handleMargin
                                        : getMarginForOutrightSale
                                }
                            />
                        }
                    />
                    <GetModal
                        app="workspace"
                        showModal={componentModal}
                        noPadding="24px"
                        onCancel={() => setComponentModal(false)}
                        closable={true}
                        content={
                            <AddComponent
                                modalFlow={true}
                                parentCallback={callback}
                            />
                        }
                    />
                </>
            ) : (
                <div
                    style={{
                        padding: '30px 100px',
                    }}
                >
                    Fetching Package...
                </div>
            )}
            <div className="HelpCenterWrapper">
                <FloatingBox
                    floatIcon={QuestionMarkIcon}
                    floatBgColor="#011A3C"
                    floatBoxClass="floatBox"
                    renderComponent={props => <HelpCenter {...props} />}
                />
            </div>
        </>
    )
}

EditPackage.propTypes = {
    match: PropTypes.any,
    productId: PropTypes.any,
    location: PropTypes.any,
}

export default EditPackage

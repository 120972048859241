import { useState } from 'react'
import CustomToolTip from 'src/components/CustomToolTip'
import { systemInfo } from './data'
import s from './systemdetailscard.module.scss'
import infoIcon from 'src/assets/images/Info-warning-blue.svg'
import { ReactComponent as Star } from 'src/assets/images/star.svg'
import { ReactComponent as StarFilled } from 'src/assets/images/star-yellow.svg'
import ButtonComponent from 'src/components/Button'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import { Dropdown, Menu } from 'antd'
import { ReactComponent as CircleDown } from 'src/assets/images/circle-down-no-stroke.svg'
import PropTypes from 'prop-types'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const SystemDetailsCard = ({
    onboardingType,
    action,
    viewingFromOutrightSaleKYCForm,
    viewingFromAccountSetup,
    handleGetStarted,
    handleAppliancePreview,
    isProfile,
    isAllSystems,
    selectNewSystem,
    openAppliancesPreview,
    isPromoPage,
    repaymentPlans,
    systemDetails,
    paymentPlans,
}) => {
    const [currentTenure, setCurrentTenure] = useState(
        systemDetails?.preferred_payment_tenure,
    )
    const { isMobile } = useMediaQueries()

    const dropdownMenu = (
        <Menu className="SystemDetailsCardMenu">
            {paymentPlans?.map((each, i) => (
                <Menu.Item
                    key={i}
                    onClick={() => {
                        setCurrentTenure(each?.tenure)
                    }}
                    disabled={each?.tenure === currentTenure}
                >
                    <span>{each?.tenureTitle}</span>
                </Menu.Item>
            ))}
        </Menu>
    )

    const mappedSystemInfo = systemInfo(
        isPromoPage,
        repaymentPlans,
        systemDetails,
        currentTenure,
        paymentPlans,
    )
    const displayableSystemInfo = mappedSystemInfo?.filter(
        item => item?.display,
    )

    return (
        <div className={s.wrapper}>
            <div className={s.systemInfoContent}>
                {displayableSystemInfo?.map((item, i) => (
                    <div key={i} className={s.rowItem}>
                        <h4>{item?.title}</h4>
                        {item?.key === 'available-plans' ? (
                            <Dropdown
                                trigger={['click']}
                                overlay={dropdownMenu}
                            >
                                <a
                                    onClick={e => e.preventDefault()}
                                    className={s.plansBtn}
                                >
                                    <span>{currentTenure} months</span>
                                    <CircleDown />
                                </a>
                            </Dropdown>
                        ) : item?.key === 'aftercare-service' ? (
                            <div className={s.aftercareInfo}>
                                <span>4/5</span>
                                <div className={s.aftercareInfo_Stars}>
                                    {[...Array(4)]?.map(i => (
                                        <StarFilled key={i} />
                                    ))}
                                    <Star />
                                </div>

                                <CustomToolTip
                                    text={
                                        'This indicates the quality of aftercare service you will experience with this package post purchase'
                                    }
                                    toolTipFontSize="12px"
                                    toolTipPadding="9px 12px 9px 8px"
                                    toolTipPopupWidth={174}
                                    arrowPositionLeft
                                    toolTipMarginLeft={-19}
                                >
                                    <img
                                        src={infoIcon}
                                        alt={'info icon'}
                                        role={'presentation'}
                                        className={s.tooltipIcon}
                                    />
                                </CustomToolTip>
                            </div>
                        ) : (
                            <p>{item?.value}</p>
                        )}
                    </div>
                ))}
            </div>
            <VerticalSpacer top={24} />
            <div className={s.btns}>
                <ButtonComponent
                    btnWidth={isMobile ? '100%' : '159.5px'}
                    btnHeight={'44px'}
                    border={'0.5px solid #004AAD'}
                    btnTextColorOutline={'#004AAD'}
                    btnBgColorOutline={'#F9FCFF'}
                    type={'outline'}
                    handleClick={openAppliancesPreview}
                >
                    What can it power?
                </ButtonComponent>
                <ButtonComponent
                    btnWidth={isMobile ? '100%' : '159.5px'}
                    btnHeight={'44px'}
                    btnBgColor={'#004AAD'}
                    btnTextColor={'#FFFFFF'}
                    handleClick={() =>
                        onboardingType === 'custom-build' ||
                        action === 'select_new_system' ||
                        viewingFromOutrightSaleKYCForm ||
                        viewingFromAccountSetup
                            ? handleGetStarted()
                            : handleAppliancePreview()
                    }
                    loading={
                        isProfile || isAllSystems
                            ? false
                            : selectNewSystem.isLoading
                    }
                >
                    Get this system
                </ButtonComponent>
            </div>
        </div>
    )
}

SystemDetailsCard.propTypes = {
    onboardingType: PropTypes.string,
    action: PropTypes.string,
    viewingFromOutrightSaleKYCForm: PropTypes.bool,
    viewingFromAccountSetup: PropTypes.bool,
    handleGetStarted: PropTypes.func,
    handleAppliancePreview: PropTypes.func,
    isProfile: PropTypes.bool,
    isAllSystems: PropTypes.bool,
    selectNewSystem: PropTypes.object,
    openAppliancesPreview: PropTypes.func,
    isPromoPage: PropTypes.bool,
    repaymentPlans: PropTypes.array,
    systemDetails: PropTypes.object,
    paymentPlans: PropTypes.array,
}

export default SystemDetailsCard
